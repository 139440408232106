import React, { useEffect, useState } from "react";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Input from "../../components/Inputs/Input/Input";
import Button from "../../components/Buttons/Button/Button";
import * as Functions from '../../../utils/functions/Functions'
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
import CodeInput from "../../components/Inputs/InputCode/Input";
import Modal from "../../components/Modals/Modal/Modal";
import { State } from "../../redux/store";

const Authentication = () => {

    const tfa = useSelector((state: State) => state.ReqTFA);

    const [email, setEmail] = useState('');
    const [errorEmail, toggleErrorEmail] = useState(false);
    const [senha, setSenha] = useState('');
    const [errorPassword, toggleErrorPassword] = useState(false);
    const [tfaCode, setCode] = useState('');

    const [time, setTime] = useState(0);
    const [loadingCode, toggleCode] = useState(false);
    const [loading, toggleLoading] = useState(false);
    const [codeSent, setCodeSent] = useState(false); // Estado para controlar o envio automático inicial

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(time === 0) return;
        const interval = setInterval(() => {
            setTime(seconds => seconds - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);

    const tryResend = () => {
        if (time !== 0) return;
        toggleCode(true);
        axios.get(defines.apiURL + "/api/LoginPermissionTFA", { withCredentials: true })
            .then((response) => {
                toggleCode(false);
                setTime(120);
                setCodeSent(true); // Marca o código como enviado
                dispatch({ type: 'ADD_NOTIFICATION', status: 1, title: "Código enviado" });
            })
            .catch((error) => {
                toggleCode(false);
                switch (error.response.status) {
                    case 406:
                        if (error.response.data.code === "TFA-3") {
                            setTime(120);
                        }
                }
                dispatch({ type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message });
            });
    }

    useEffect(() => {
        if (tfa && !codeSent) {
            // Envia o código automaticamente uma vez, quando o modal abre e o tempo permite
            tryResend();
        }
    }, [tfa, codeSent]); // Remove 'time' das dependências para evitar reenviar repetidamente

    const tryAuth = () => {
        toggleErrorEmail(false);
        toggleErrorPassword(false);
        if (!Functions.verifyEmail(email)) {
            toggleErrorEmail(true);
            return;
        }
        if (senha.length < 12) {
            toggleErrorPassword(true);
            return;
        }

        axios.post(defines.apiURL + "/api/LoginPermissionTFA", { email, senha, tfaCode, api: 'sso' }, { withCredentials: true })
            .then((response) => {
                toggleLoading(false);
                dispatch({ type: 'ADD_NOTIFICATION', status: 1, title: "Autenticado com sucesso!" });
                dispatch({ type: 'REMOVE_TFA' });
            })
            .catch((error) => {
                toggleLoading(false);
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
            });
    }

    if (!tfa) return <></>;

    return (
        <Modal title="Autenticação para dados sensíveis" loading={loading} toggleModal={() => {
            dispatch({ type: 'REMOVE_TFA' });
        }}>
            <div style={{ width: '500px', display: 'flex', flexDirection: 'column', gridGap: '15px 0px', alignItems: 'center', justifyContent: 'center' }}>
                <Body.Text>A sua permissão para alterar dados sensiveis expirou, realize a autenticação e tente novamente!</Body.Text>
                <Input text={email} setText={setEmail} title="Email" error={errorEmail} />
                <Input text={senha} setText={setSenha} title="Senha" error={errorPassword} password={true} />
                <CodeInput setText={setCode} title="Código" action={() => tryAuth()} />
                <br />
                <Button text="Continuar" action={() => tryAuth()} loading={loading} />
                {time !== 0 ? (
                    <Body.Text style={{ textAlign: 'center' }}>Aguarde {Functions.getTime(time)} para enviar novamente</Body.Text>
                ) : (
                    <ButtonWithoutBG text="Reenviar código" loading={loadingCode} action={tryResend} />
                )}
            </div>
        </Modal>
    );
};

export default Authentication;
