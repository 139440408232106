import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import SearchIcon from '../../../assets/icons/Search.svg'
import AddIcon from '../../../assets/icons/add-icon.svg'
import * as Functions from  '../../../utils/functions/Functions'
import * as defines from  '../../../utils/defines/Defines'
import * as Types from '../../../utils/types/Types'
import Input from "../../components/Inputs/Input/Input";
import Button from "../../components/Buttons/Button/Button";
import * as Modals from './Modals/Modals'
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";
import { State } from "../../redux/store";
import Avatar from '../../../assets/images/avatar.png'
import SendInvite from "./Modals/SendInvite";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";

type FilialProps = {
    idFilial: string,
    nomeFilial: string,
    nomeEmpresa: string,
    endereco: string,
    cnpj: string,
    cnpjFilial: string,
    tipoUsuarioEmpresarial: number | null
}

type UsuarioProps = {
    idEmpresarial: string,
    idUsuario: string,
    fotoUsuario: string | null,
    ddd: string,
    celular: string,
    nomeCompleto: string,
    email: string,
    cargo: number,
    encarregadoDados: number
}

const ManageUsers = () => {

    const location = useLocation();
    const { idEmpresa, nomeEmpresa, idFilial, nomeFilial }: { idEmpresa: string, nomeEmpresa: string, idFilial: string, nomeFilial: string } = location.state || {}; 

    const acessLevel = useSelector((state: State) => state.acessLevel.acessLevel);
    const userType = useSelector((state: State) => state.userType.userType)
    
    const [hasLength, toggleHasLength] = useState(false)
    const [hasUser, toggleHasUser] = useState(false)
    const [modalCreateUser, toggleCreateUser] = useState(false)
    const [modalUnits, toggleUnits] = useState(false)
    const [modalDelete, toggleDelete] = useState(false)
    const [data, setData] = useState<UsuarioProps[]>([])
    const [filteredUsers, setFiltered] = useState<UsuarioProps[]>([])
    const [loading, toggleLoading] = useState(true)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [nomeCompleto, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setTelefone] = useState('')
    const [imagemUsuario, setImagem] = useState('')
    const [nivelAcesso, setNivel] = useState(0)
    const [encarregadoDados, setEncarregado] = useState(0)
    const [idUsuario, setId] = useState('')
    const [idEmpresarial, setIdEmpresarial] = useState('')
    const [loadedUserData, setUserData] = useState<UsuarioProps>()
    const inputRef = useRef<any>()

    const getUsers = () => {
        toggleLoading(true)
        axios.get(defines.apiURL + "/api/EmpresarialCRUD?idFilial=" + Functions.ReplaceSpecialCharacters(idFilial) + "&userTypeRequest=" + userType, { withCredentials: true })
            .then((response) => {
            const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
            setData(sorted)
            setFiltered(sorted)
            toggleLoading(false)
            })
            .catch((error) => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
            })
        }

    const selectUser = (id: string) => {
        const replacedId = Functions.ReplaceSpecialCharacters(id)
        if(id === idUsuario && hasUser){
            toggleHasUser(false)
            return
        }
        toggleLoading(true)
        setNome('')
        setDDD('')
        setEmail('')
        setTelefone('')
        setUserData(undefined)
        setId(id)
        setIdEmpresarial('')
        axios.get(defines.apiURL+"/api/EmpresarialCRUD?id=" + Functions.ReplaceSpecialCharacters(id) + "&userTypeRequest=" + userType + "&idFilial=" + Functions.ReplaceSpecialCharacters(idFilial),  {withCredentials: true})
            .then((response)=>{
                let responseData = response.data.content[0]
                setNome(responseData.nomeCompleto)
                setDDD(responseData.ddd)
                setEmail(responseData.email)
                setImagem(responseData.fotoUsuario)
                setTelefone(responseData.celular)
                setUserData(responseData)
                setId(id)
                setIdEmpresarial(responseData.idEmpresarial)
                setEncarregado(responseData.encarregadoDados)
                setNivel(responseData.nivelAcesso)

                toggleHasUser(true)
                toggleLoading(false)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data)
            toggleHasLength(false)
            return
        }
        toggleHasLength(true)
        const filtered = data.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    const deleteUser = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL+"/api/EmpresarialCRUD?id="+ Functions.ReplaceSpecialCharacters(idEmpresarial) +"&userTypeRequest=" + userType + "&idFilial=" + Functions.ReplaceSpecialCharacters(idFilial) ,  {withCredentials: true})
        .then((response)=>{
            dispatch({type: 'ADD_NOTIFICATION', status: 1, text: 'Usuário desvinculado com sucesso', title: ''})
            toggleHasUser(false)
            toggleDelete(false)
            getUsers()
            toggleLoading(false)
        })
        .catch((error)=>{
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }


    const updateUser = () => {
        if (!loadedUserData) return;

        toggleLoading(true);
        const updatedFields: Partial<UsuarioProps> = {};

        if (email !== loadedUserData.email) updatedFields.email = email;
        if (celular !== loadedUserData.celular) updatedFields.celular = celular;
        if (ddd !== loadedUserData.ddd) updatedFields.ddd = ddd;
        if (nomeCompleto !== loadedUserData.nomeCompleto) updatedFields.nomeCompleto = nomeCompleto;
        if (nivelAcesso !== loadedUserData.cargo) updatedFields.cargo = nivelAcesso;
        if (encarregadoDados !== loadedUserData.encarregadoDados) updatedFields.encarregadoDados = encarregadoDados;

        if (Object.keys(updatedFields).length === 0) return;

        updatedFields.idUsuario = idUsuario;
        updatedFields.idEmpresarial = loadedUserData.idEmpresarial;

        axios.put(defines.apiURL + "/api/UpdateUser?userTypeRequest=" + userType + "&idFilial=" + Functions.ReplaceSpecialCharacters(idFilial),
            updatedFields, { withCredentials: true })
            .then((response) => {
                dispatch({ type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Dados do usuario alterados com sucesso' });
                selectUser(idUsuario);
                getUsers();
                toggleLoading(false);
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
                toggleLoading(false);
            });
    }


    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL + "/api/EmpresarialCRUD?idFilial=" + Functions.ReplaceSpecialCharacters(idFilial) + "&userTypeRequest=" + userType, { withCredentials: true })
            .then((response) => {
            const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
            setData(sorted)
            setFiltered(sorted)
            toggleLoading(false)
            })
            .catch((error) => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
            })
    }, [dispatch, navigate])


    const getCargo = (cargo: number) => {
        switch (cargo) {
            case defines.CargoEmpresarial['Administrador']:
                return 'Administrador';
            case defines.CargoEmpresarial['Padrão']:
                return 'Padrão';
        }
    }

    return <Body.Container>
        <Sidebar type={userType} content={defines.ContentSidebar.MANAGE_COMPANY} />
        <Body.Content style={{flexDirection: 'row'}}>
            <Body.HalfContent style={hasUser?{width: 'calc(100% - 120px)'}:{width: '100%'}}>
                <Body.Row style={{width: "100%", height: '140px',
                    justifyContent: 'space-between', alignItems: 'center'}}>
                    <Breadcrumb empresa={nomeEmpresa} filial={nomeFilial} disableSublinks/>
                    <Body.Row style={{maxWidth: '90%', minWidth: '50px', alignItems: 'center'}}>
                        <ButtonWithoutBG text="Trocar Filial" action={() => {navigate('/empresas/filiais', { state: { idEmpresa: idEmpresa, nome: nomeEmpresa } })}} />
                        <Body.FindContent>
                            <Body.Find style={hasLength?{width: '300px'}:{}}
                                onChange={e => filterData(e.target.value)}
                                ref={inputRef} />
                            <Body.Icon src={SearchIcon} style={{cursor: 'text'}}
                                onClick={()=>inputRef.current.focus()} />
                        </Body.FindContent>
                        {acessLevel !== defines.CargoTectrol['Padrão'] && (
                            <Body.HeaderButton onClick={()=>toggleCreateUser(true)}>
                                <Body.Icon src={AddIcon} style={{position: 'relative', top: 0, left: 0}} />
                            </Body.HeaderButton>
                        )}
                    </Body.Row>                        
                </Body.Row>
                <Body.Scroll style={{ maxHeight: 'calc(100% - 120px)' }}>
                    {filteredUsers.length === 0 ? (
                        <Body.Text>Nenhum usuário encontrado</Body.Text>
                    ) : (
                        filteredUsers.map((item, index) => (
                            <Body.SelectUser style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between' }} onClick={() => selectUser(item.idUsuario)} key={index.toString()}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <Body.Photo src={item.fotoUsuario || Avatar} />
                                        <Body.Text>{item.nomeCompleto}</Body.Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                        <Body.NoteText>{getCargo(item.cargo)}</Body.NoteText>
                                        {item.encarregadoDados ? <Body.NoteText>Encarregado de Dados</Body.NoteText> : null}
                                    </div>
                            </Body.SelectUser>
                        ))
                    )}
                </Body.Scroll>
            </Body.HalfContent>
            {hasUser?<Body.HalfContent style={{alignItems: 'center'
                    , width: hasUser?'100px':'0px'}}>
                    <Body.Photo src={imagemUsuario ? imagemUsuario : Avatar} style={{width: '120px',
                        borderRadius: '60px',height: '120px'}}/>
                    <Body.Scroll style={{height: '100%', marginBottom: '10px',
                        maxHeight: 'calc(100vh - 80px)',
                        display: 'flex', flexDirection: 'column', gridGap: '20px 0px'}}>
                        <Input text={nomeCompleto} setText={setNome} title="Nome Completo" disabled={acessLevel === defines.CargoTectrol['Padrão']} />
                        <Input text={email} setText={setEmail} title="Email" disabled={acessLevel === defines.CargoTectrol['Padrão']} />
                        <Body.Row>
                            <div style={{width: '20%'}}>
                                <Input text={ddd} setText={setDDD} title="DDD"
                                    limit={2} disabled={acessLevel === defines.CargoTectrol['Padrão']} />
                            </div>
                            <div style={{width: '79%'}}>
                                <Input text={celular} setText={setTelefone} title="Telefone" disabled={acessLevel === defines.CargoTectrol['Padrão']} />
                            </div>
                        </Body.Row>
                    </Body.Scroll>
                    {acessLevel !== defines.CargoTectrol['Padrão'] && (
                        <Body.Row>
                            <Button text="Salvar" action={()=>updateUser()} width={155} loading={loading}/>
                            <Button text="Desvincular usuário" action={()=>toggleDelete(true)} width={155}
                                color="red" loading={loading}/>
                        </Body.Row>
                    )}
            </Body.HalfContent>:null}
        </Body.Content>

        {modalDelete?<Modals.ModalDeleteUser toggle={toggleDelete} idUsuario={idUsuario}
            nome={nomeCompleto} action={deleteUser} nomeFilial={nomeFilial} />:null}
        {loading?<Loading />:null}
        {modalCreateUser?<SendInvite toggle={toggleCreateUser} idFilial={idFilial} nomeFilial={nomeFilial}/>: null}

    </Body.Container>
}

export default ManageUsers
