import React, { useEffect, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import Input from "../../components/Inputs/Input/Input";
import * as Types from '../../../utils/types/Types'
// import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
// import imageCompression from "browser-image-compression";
import Button from "../../components/Buttons/Button/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
// import * as Modals from './modals/Modals'
import { State } from "../../redux/store";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";
import VisitaIcon from '../../../assets/icons/visita-icon.svg'
import UsuarioIcon from '../../../assets/icons/usuario-icon.svg'
import TecnicoIcon from '../../../assets/icons/tecnico-icon.svg'
import TransparentTecnicoIcon from '../../../assets/icons/tecnico-icon-transparent.svg'
import EmpresaIcon from '../../../assets/icons/empresa-icon.svg'
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
import ButtonWrapper from "../../components/Buttons/ButtonWrapper/ButtonWrapper";
import CodeInput from "../../components/Inputs/InputCode/Input";
import ManageAccountIcon from '../../../assets/icons/manage-account-tectrol-icon.svg'
import TectrolLogo from '../../../assets/icons/tectrol-logo.svg'
import DinamoLogo from '../../../assets/icons/dinamo-logo.svg'

const Content = {
    CompanyUser: 0,
    InputCompanyCode: 1,
    AcceptTerms: 2,
}

type AcceptTermsProps = {
    setContent: (content: number) => void,
    tfaCode: string
    toggleVisitante: (value: boolean) => void
    toggleEmpresa: (value: boolean) => void
    toggleTectrol: (value: boolean) => void
}

const AcceptTerms: React.FC<AcceptTermsProps> = ({
    setContent, tfaCode, toggleVisitante, toggleEmpresa, toggleTectrol
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [company, setCompany] = useState('')
    const [unity, setUnity] = useState('')
    const [inviteType, setInviteType] = useState(0)
    const [loading, toggleLoading] = useState(false)

    useEffect(() => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/ConviteUsuarioEmpresarial?tfaCode=" + tfaCode, {withCredentials: true})
            .then((response) => {
                toggleLoading(false)
                setCompany(response.data.content.nomeEmpresa)
                setUnity(response.data.content.filial)
                setInviteType(response.data.content.tipoConvite)
                axios.get(defines.apiURL+"/api/Home?userTypeSelection=1", {withCredentials: true})
                .then((response) => {
                    toggleVisitante(response.data.content.visitante)
                    toggleEmpresa(response.data.content.empresarial)
                    toggleTectrol(response.data.content.tectrol)
                })
                .catch((error) => {
                    Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                })  
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })  
    },[dispatch, navigate])

    const confirmInvite = () => {
        toggleLoading(true)
        axios.post(defines.apiURL+"/api/ConviteUsuarioEmpresarial", {
            tfaCode: tfaCode
        }, {withCredentials: true})
        .then((response) => {
            toggleLoading(false)
            setContent(Content.CompanyUser)
            navigate('/menu')
            dispatch({
                type: 'ADD_NOTIFICATION',
                status: 1,
                title: 'Vinculo realizado com sucesso!',
                text: 'Você agora está vinculado à empresa ' + company + ' na filial ' + unity,
            });
        })
        .catch((error) => {
            toggleLoading(false)
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
        })
    }

    return <Body.CardLogin>
        <Body.SubTitle>Vínculo empresarial</Body.SubTitle>
        <Body.Text>Você recebeu um convite para se tornar um <b>usuário {inviteType === 3 ? "administrador" : (inviteType === 1 ? "empresarial" : null)}</b></Body.Text>
        <Body.Text>Sua conta será vinculada à empresa <b>{company}</b> na filial <b>{unity}</b></Body.Text>

        <Body.Row>
            <Body.Icon src={EmpresaIcon} />
            <Body.Column>
                <Body.Text>Ao vincular sua conta, você não poderá acessar a área empresarial de nenhuma outra empresa.</Body.Text>
            </Body.Column>
        </Body.Row>

        <Body.Row>
            <Body.Icon src={TecnicoIcon} />
            <Body.Column>
                <Body.Text>Caso queira visualizar aplicações de outras empresas, solicite um acesso de Usuário Visitante.</Body.Text>
            </Body.Column>
        </Body.Row>

        <br />
        <Button text="Estou ciente, vincular minha conta" action={() => {confirmInvite()}} loading={loading}/>
        <ButtonWithoutBG text="Voltar para o menu inicial" action={() => {setContent(Content.CompanyUser)}} />
    </Body.CardLogin>
}

type InputCompanyCodeProps = {
    setContent: (content: number) => void
    setCode: (code: string) => void,
    bindCode: () => void
}

const InputCompanyCode: React.FC<InputCompanyCodeProps> = ({
    setContent,
    setCode,
    bindCode,
}) => {
    return <Body.CardLogin>
    <Body.SubTitle>Vínculo empresarial</Body.SubTitle>
    <Body.Text>Insira o código do convite que você recebeu em seu e-mail.</Body.Text>

    <CodeInput setText={setCode} action={() => {}}/>

    <br />
    <Button text="Enviar" action={() => {bindCode()}} />
    <ButtonWithoutBG text="Voltar para o menu inicial" action={() => {setContent(Content.CompanyUser)}} />
</Body.CardLogin>
}

type CompanyUserProps = {
    getNome: () => string
    logout: () => void
    setUserType: (type: number) => void
    isVisitante: boolean
    isEmpresa: boolean
    isTectrol: boolean
    empresa: string
    setContent: (content: number) => void
}

const CompanyUser: React.FC<CompanyUserProps> = ({
    getNome,
    logout,
    setUserType,
    isVisitante,
    isEmpresa,
    isTectrol,
    empresa,
    setContent
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return <Body.CardLogin>
    <Body.SubTitle>Olá, <b>{getNome()}</b></Body.SubTitle>

        {isTectrol? <ButtonWrapper style={{ width: '100%' }} action={() => { setUserType(defines.TypeSidebar.TECTROL_USER) }}>
            <Body.BtnGrad>
                <Body.Row>
                    <Body.Icon src={ManageAccountIcon} />
                    <Body.Column>
                        <Body.ButtonTitleWhite>Entrar como <b>usuário administrador</b></Body.ButtonTitleWhite>
                        <Body.TextWhite>Acesse informações referentes ao sistema e usuários de diferentes empresas.</Body.TextWhite>
                    </Body.Column>
                </Body.Row>
            </Body.BtnGrad>
        </ButtonWrapper>: 
        <>
            {isEmpresa ? <ButtonWrapper style={{ width: '100%' }} action={() => {setUserType(defines.TypeSidebar.DEFAULT_USER)}}>
                    <Body.Card>
                        <Body.Row>
                            <Body.Icon src={UsuarioIcon} />
                            <Body.Column>
                                <Body.ButtonTitle>Entrar como usuário da empresa {empresa}</Body.ButtonTitle>
                                <Body.Text>Acesse informações referentes à sua empresa</Body.Text>
                            </Body.Column>
                        </Body.Row>
                    </Body.Card>
                </ButtonWrapper> : 
                <ButtonWrapper style={{ width: '100%' }} action={() => {}}>
                    <Body.CardDisabled>
                        <Body.Row>
                            <Body.Icon src={TransparentTecnicoIcon} />
                            <Body.Column>
                                <Body.ButtonTitleDisabled>Sem vínculo ativo</Body.ButtonTitleDisabled>
                                <Body.TextDisabled>Você não está vinculado à nenhuma empresa</Body.TextDisabled>
                            </Body.Column>
                        </Body.Row>
                    </Body.CardDisabled>
                </ButtonWrapper>}
        </>
        }

        {isVisitante ? 
        <>
                <ButtonWrapper style={{ width: '100%' }} action={() => {setUserType(defines.TypeSidebar.VISITING_USER)}}>
                <Body.Card>
                    <Body.Row>
                        <Body.Icon src={VisitaIcon} />
                        <Body.Column>
                            <Body.ButtonTitle>Entrar como usuário visitante</Body.ButtonTitle>
                            <Body.Text>Acesse as informações referentes às empresas que te liberaram acesso de visitante</Body.Text>
                        </Body.Column>
                    </Body.Row>
                </Body.Card>
            </ButtonWrapper>
        </>
        : 
        <ButtonWrapper style={{ width: '100%' }} action={() => {}}>
            <Body.CardDisabled>
                <Body.Row>
                    <Body.Icon src={TransparentTecnicoIcon} />
                    <Body.Column>
                        <Body.ButtonTitleDisabled>Sem vínculo de visitante</Body.ButtonTitleDisabled>
                        <Body.TextDisabled>Você não pode acessar o sistema como visitante.</Body.TextDisabled>
                    </Body.Column>
                </Body.Row>
            </Body.CardDisabled>
        </ButtonWrapper>
        }
        

        {!isTectrol && !isEmpresa ?
        <>
            <br />
            <Button text="Inserir token de vínculo empresarial" action={() => {
                navigate('/menu/vincular-filial')
                setContent(Content.InputCompanyCode)
                }} />
        </> : null}

        <ButtonWithoutBG text="Logout" action={logout} />
    </Body.CardLogin>
}


type AccessMenuProps = {
    fromEmailInvite?: boolean
}

const AccessMenu: React.FC<AccessMenuProps> = ({
    fromEmailInvite = false
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loading, toggleLoading] = useState(true)
    const [content, setContent] = useState(fromEmailInvite ? Content.InputCompanyCode : Content.CompanyUser)

    const [code, setCode] = useState('')

    const [nome, setNome] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [isVisitante, toggleVisitante] = useState(false)
    const [isEmpresa, toggleEmpresa] = useState(false)
    const [isTectrol, toggleTectrol] = useState(false)

    const bindCode = () => {
        axios.get(defines.apiURL+"/api/ConviteUsuarioEmpresarial?tfaCode=" + code, {withCredentials: true})
        .then((response)=>{
            setContent(Content.AcceptTerms)
        })
        .catch((error)=>{
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.info)
        })
    }

    const getContent = () => {
        switch(content){
            case Content.CompanyUser:
                return <CompanyUser
                getNome={getNome} logout={logout}
                setUserType={setUserType} isVisitante={isVisitante}
                isEmpresa={isEmpresa} isTectrol={isTectrol}
                empresa={empresa}
                setContent={setContent}
                />
            case Content.InputCompanyCode:
                return <InputCompanyCode
                bindCode={bindCode}
                setContent={setContent}
                setCode={setCode} />
            case Content.AcceptTerms:
                return <AcceptTerms 
                tfaCode={code}
                setContent={setContent}
                toggleVisitante={toggleVisitante}
                toggleEmpresa={toggleEmpresa}
                toggleTectrol={toggleTectrol}
                />
        }
    }

    useEffect(() => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/Home?userTypeSelection=1", {withCredentials: true})
            .then((response) => {
                setNome(response.data.content.nome)
                setEmpresa(response.data.content.nomeEmpresa)
                toggleVisitante(response.data.content.visitante)
                toggleEmpresa(response.data.content.empresarial)
                toggleTectrol(response.data.content.tectrol)
                toggleLoading(false)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })  
    },[dispatch, navigate])

    const getNome = () => {
        if(nome){
            return nome.split(' ')[0]
        }
        return ''
    }

    const logout = () => {
        axios.get(defines.apiURL+"/api/Logout",  {withCredentials: true})
            .then((response)=>{
                navigate('/')
            })
            .catch((error: any)=>{
                if(error.response.data.code === 'ACS-9'){
                    navigate('/')
                    return
                }
                dispatch({type: 'ADD_NOTIFICATION', title: "Erro", status: 3, text: error.response.data.message})
            })
            .finally(() => {
                localStorage.removeItem("persist:root")
                localStorage.removeItem("@logged")
            })
    }

    const setUserType = (type: number) => {
        dispatch({type: 'SET_USER_TYPE', userType: type})
        if(type === defines.TypeSidebar.DEFAULT_USER) dispatch({ type: 'REQ_UNITY' })
        else navigate('/minha-conta')
    }


    return <Body.Container>
        <Body.Row>
            <Body.CompanyLogo style={{ height: '23px', marginLeft: '20px' }} src={DinamoLogo} />
            <Body.CompanyLogo src={TectrolLogo} />
        </Body.Row>
        <Body.Title>Conecta</Body.Title>

        {getContent()}

        {loading?<Loading />:null}
    </Body.Container>
}

export default AccessMenu