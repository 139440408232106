import * as Actions from "./actionsType";
const userType = (
    state: Actions.UserTypeProps = {userType: 1},
    action: Actions.UserTypeProps
) => {
    switch(action.type){
        case Actions.SET_USER_TYPE:
            return {userType: action.userType}
    }
    return state
}

export default userType