import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import SearchIcon from '../../../assets/icons/Search.svg'
import AddIcon from '../../../assets/icons/add-icon.svg'
import * as Functions from  '../../../utils/functions/Functions'
import * as defines from  '../../../utils/defines/Defines'
import * as Types from '../../../utils/types/Types'
import Input from "../../components/Inputs/Input/Input";
import Button from "../../components/Buttons/Button/Button";
import * as Modals from './Modals/Modals'
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";
import { State } from "../../redux/store";
import Avatar from '../../../assets/images/avatar.png'
import SendInvite from "./Modals/SendInvite";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
import ButtonWrapper from "../../components/Buttons/ButtonWrapper/ButtonWrapper";
import DeleteIcon from '../../../assets/icons/delete-red-material-icon.svg'
import { set } from "date-fns";
import DeleteUser from "./Modals/DeleteUser";
import UpdateCargo from "./Modals/UpdateCargo";

type FilialProps = {
    idFilial: string,
    nomeFilial: string,
    nomeEmpresa: string,
    endereco: string,
    cnpj: string,
    cnpjFilial: string,
    tipoUsuarioEmpresarial: number | null
}

type UsuarioProps = {
    idEmpresarial: string,
    idUsuario: string,
    fotoUsuario: string | null,
    ddd: string,
    celular: string,
    nomeCompleto: string,
    email: string,
    cargo: number,
    encarregadoDados: number
}


const DefaultManageUsers = () => {

    const acessLevel = useSelector((state: State) => state.acessLevel.acessLevel);
    const userType = useSelector((state: State) => state.userType.userType)
    const selectedUnity = useSelector((state: State) => state.selectedUnity.selectedUnity);

    const [hasLength, toggleHasLength] = useState(false)
    const [hasUser, toggleHasUser] = useState(false)
    const [modalCreateUser, toggleCreateUser] = useState(false)
    const [modalDelete, toggleDelete] = useState(false)
    const [data, setData] = useState<UsuarioProps[]>([])
    const [filteredUsers, setFiltered] = useState<UsuarioProps[]>([])
    const [loading, toggleLoading] = useState(true)
    const [changeCargo, toggleChangeCargo] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [nomeCompleto, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setTelefone] = useState('')
    const [imagemUsuario, setImagem] = useState('')
    const [nivelAcesso, setNivel] = useState(0)
    const [encarregadoDados, setEncarregado] = useState(0)
    const [idUsuario, setId] = useState('')
    const [loadedUserData, setUserData] = useState<UsuarioProps>()
    const [nomeFilial, setNomeFilial] = useState('')
    const inputRef = useRef<any>()
    const [selectedUser, setSelectedUser] = useState<UsuarioProps>()

    const getUsers = () => {
        toggleLoading(true)
        axios.get(defines.apiURL + "/api/EmpresarialCRUD?userTypeRequest=" + userType + "&idFilial=" + Functions.ReplaceSpecialCharacters(selectedUnity), { withCredentials: true })
            .then((response) => {
            const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
            setData(sorted)
            setFiltered(sorted)
            toggleLoading(false)
            toggleHasUser(false)
            })
            .catch((error) => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
            })
    }

    const selectUser = (id: string) => {
        const replacedId = Functions.ReplaceSpecialCharacters(id)
        if(id === idUsuario && hasUser){
            toggleHasUser(false)
            return
        }
        toggleLoading(true)
        setNome('')
        setDDD('')
        setEmail('')
        setImagem('')
        setTelefone('')
        setUserData(undefined)
        setId(id)
        axios.get(defines.apiURL+"/api/EmpresarialCRUD?id=" + Functions.ReplaceSpecialCharacters(id) + "&userTypeRequest=" + userType + "&idFilial=" + Functions.ReplaceSpecialCharacters(selectedUnity),  {withCredentials: true})
            .then((response)=>{
                let responseData = response.data.content[0]
                setNome(responseData.nomeCompleto)
                setDDD(responseData.ddd)
                setEmail(responseData.email)
                setImagem(responseData.fotoUsuario)
                setTelefone(responseData.celular)
                setUserData(responseData)
                setId(id)
                setEncarregado(responseData.encarregadoDados)
                setNivel(responseData.nivelAcesso)
                toggleHasUser(true)
                toggleLoading(false)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data)
            toggleHasLength(false)
            return
        }
        toggleHasLength(true)
        const filtered = data.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }


    useEffect(()=>{

        if(acessLevel !== defines.CargoEmpresarial['Administrador']) navigate('/permissao')

        toggleLoading(true)
        axios.get(defines.apiURL + "/api/EmpresarialCRUD?userTypeRequest=" + userType + "&idFilial=" + Functions.ReplaceSpecialCharacters(selectedUnity), { withCredentials: true })
            .then((response) => {
            const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
            setData(sorted)
            setFiltered(sorted)
            toggleLoading(false)
            })
            .catch((error) => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
            })
            
        axios.get(defines.apiURL + "/api/Home?userTypeRequest=" + userType + "&filialInfo=" + Functions.ReplaceSpecialCharacters(selectedUnity), { withCredentials: true })
            .then((response) => {
                setNomeFilial(response.data.content.nomeFilial)
                toggleLoading(false)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, navigate])

    const userDelete = (user: UsuarioProps) => {
        setSelectedUser(user)
        toggleDelete(true)
    }

    const userUpdate = (user: UsuarioProps) => {
        setSelectedUser(user)
        toggleChangeCargo(true)
    }



    const getCargo = (cargo: number) => {
        switch(cargo){
            case defines.CargoEmpresarial['Administrador']: return "Administrador"
            case defines.CargoEmpresarial['Padrão']: return "Padrão"
            default: return "Cargo Indisponível"
        }
    }

    return <Body.Container>
        <Sidebar type={userType} content={defines.ContentSidebar.MANAGE_USERS} />
        <Body.Content style={{flexDirection: 'row'}}>
            <Body.HalfContent style={hasUser?{width: 'calc(100% - 120px)'}:{width: '100%'}}>
                <Body.Row style={{width: "100%", height: '140px',
                    justifyContent: 'space-between', alignItems: 'center'}}>
                    <Breadcrumb />
                    <Body.Row style={{maxWidth: '90%', minWidth: '50px', alignItems: 'center'}}>
                        <Body.FindContent>
                            <Body.Find style={hasLength?{width: '300px'}:{}}
                                onChange={e => filterData(e.target.value)}
                                ref={inputRef} />
                            <Body.Icon src={SearchIcon} style={{cursor: 'text'}}
                                onClick={()=>inputRef.current.focus()} />
                        </Body.FindContent>
                        {acessLevel === defines.CargoEmpresarial['Administrador'] ? (
                            <Body.HeaderButton onClick={()=>toggleCreateUser(true)}>
                                <Body.Icon src={AddIcon} style={{position: 'relative', top: 0, left: 0}} />
                            </Body.HeaderButton>
                        ) : null}
                    </Body.Row>                        
                </Body.Row>
                <Body.Scroll style={{maxHeight: 'calc(100% - 120px)'}}>
                    {filteredUsers.length > 0 ? (
                        filteredUsers.map((item, index) => {
                            return (
                                <Body.Row style={{ alignItems: 'center' }} key={index.toString()}>
                                    <Body.SelectUser onClick={() => selectUser(item.idUsuario)}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <Body.Photo src={item.fotoUsuario || Avatar} />
                                            <Body.Text>{item.nomeCompleto}</Body.Text>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Body.NoteText>{getCargo(item.cargo)}</Body.NoteText>
                                            {acessLevel === defines.CargoEmpresarial['Administrador'] ? (
                                                <ButtonWrapper
                                                    style={{
                                                        marginLeft: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                    action={() => { userDelete(item) }}
                                                >
                                                    <Body.DeleteIcon src={DeleteIcon} />
                                                </ButtonWrapper>
                                            ) : null}
                                        </div>
                                    </Body.SelectUser>
                                </Body.Row>
                            );
                        })
                    ) : (
                        <Body.Text>Não existem usuários para mostrar.</Body.Text>
                    )}
                </Body.Scroll>
            </Body.HalfContent>
            {hasUser?<Body.HalfContent style={{alignItems: 'center'
                    , width: hasUser?'100px':'0px'}}>
                    <Body.Photo src={imagemUsuario || Avatar} style={{width: '120px',
                        borderRadius: '60px',height: '120px'}}/>
                    <Body.Scroll style={{height: '100%', marginBottom: '10px',
                        maxHeight: 'calc(100vh - 80px)',
                        display: 'flex', flexDirection: 'column', gridGap: '20px 0px'}}>
                        <Input text={nomeCompleto} setText={setNome} title="Nome Completo" disabled/>
                        <Input text={email} setText={setEmail} title="Email" disabled/>
                        <Body.Row>
                            <div style={{width: '20%'}}>
                                <Input text={ddd} setText={setDDD} title="DDD"
                                    limit={2} disabled/>
                            </div>
                            <div style={{width: '79%'}}>
                                <Input text={celular} setText={setTelefone} title="Telefone" disabled/>
                            </div>
                        </Body.Row>
                        {acessLevel !== defines.CargoEmpresarial['Padrão'] && (
                        <Body.Row>
                            <Button text="Alterar Cargo" action={()=>{userUpdate(loadedUserData!)}}/>
                        </Body.Row>
                        )}
                    </Body.Scroll>
            </Body.HalfContent>:null}
        </Body.Content>

        {loading?<Loading />:null}
        {modalCreateUser?<SendInvite toggle={toggleCreateUser} nomeFilial={nomeFilial || 'atual'} idFilial={selectedUnity} />: null}
        {modalDelete && selectedUser?<DeleteUser toggle={toggleDelete} usuario={selectedUser} nomeFilial={nomeFilial || 'atual'} idFilial={selectedUnity} regenerate={getUsers}/>: null}
        {changeCargo && selectedUser?<UpdateCargo toggle={toggleChangeCargo} usuario={selectedUser} idFilial={selectedUnity} regenerate={getUsers}/>: null}

    </Body.Container>
}

export default DefaultManageUsers
