import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import Input from "../../components/Inputs/Input/Input";
import * as Types from '../../../utils/types/Types'
import imageCompression from "browser-image-compression";
import Button from "../../components/Buttons/Button/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import * as Modals from './modals/Modals'
import { State } from "../../redux/store";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";
import PersonalData from "./personalData";
import CompanyData from "./companyData";
import Avator from '../../../assets/images/avatar.png'
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
import SelectUnity from "./modals/SelectUnity";
import Authentication from "../authentication/Authentication";

type UsuarioProps = {
    nomeCompleto: string;
    nome: string;
    sobrenome: string;
    foto: string;
    ddd: string;
    celular: string;
    email: string;
    empresa: string;
    cnpj: string;
    idUsuario: string;
};

const MyAccount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const acessLevel = useSelector((state: State) => state.acessLevel.acessLevel);
    const userType = useSelector((state: State) => state.userType.userType);
    const selectedUnity = useSelector((state: State) => state.selectedUnity.selectedUnity);

    const [loading, toggleLoading] = useState(true);
    const [changePWD, togglePWD] = useState(false);
    const [deleteAccount, toggleDelete] = useState(false);
    const [selectUnity, toggleUnity] = useState(false);

    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const [usuarioOriginal, setUsuarioOriginal] = useState<UsuarioProps>({
        nomeCompleto: '',
        nome: '',
        sobrenome: '',
        foto: '',
        ddd: '',
        celular: '',
        email: '',
        empresa: '',
        cnpj: '',
        idUsuario: ''
    });
    const [usuarioUpdate, setUsuarioUpdate] = useState<UsuarioProps>({
        nomeCompleto: '',
        nome: '',
        sobrenome: '',
        foto: '',
        ddd: '',
        celular: '',
        email: '',
        empresa: '',
        cnpj: '',
        idUsuario: ''
    });

    const [loadingSave, toggleSave] = useState(false);

    const [isVisitante, toggleVisitante] = useState(false);
    const [isEmpresa, toggleEmpresa] = useState(false);
    const [isTectrol, toggleTectrol] = useState(false);

    const [filiais, setFiliais] = useState<Types.Unidade[]>([]);

    const [fieldsChanged, setFieldsChanged] = useState(0);

    useEffect(() => {
        toggleLoading(true);

        const promises = [];

        const userInfoRequest = axios.get(defines.apiURL + "/api/Home?currentUserInfo=1&userTypeRequest=" + userType + "&idFilial=" + Functions.ReplaceSpecialCharacters(selectedUnity), { withCredentials: true })
            .then((response) => {
                const firstName = response.data.content.nomeCompleto.split(' ')[0];
                const lastName = response.data.content.nomeCompleto.split(' ').slice(1).join(' ');
                setUsuarioOriginal({
                    nomeCompleto: response.data.content.nomeCompleto,
                    nome: firstName,
                    sobrenome: lastName,
                    foto: response.data.content.fotoUsuario,
                    ddd: response.data.content.ddd,
                    celular: response.data.content.celular,
                    email: response.data.content.email,
                    empresa: response.data.content.nomeEmpresa,
                    cnpj: response.data.content.cnpjEmpresa,
                    idUsuario: response.data.content.idUsuario
                });
                setUsuarioUpdate({
                    nomeCompleto: response.data.content.nomeCompleto,
                    nome: firstName,
                    sobrenome: lastName,
                    foto: response.data.content.fotoUsuario,
                    ddd: response.data.content.ddd,
                    celular: response.data.content.celular,
                    email: response.data.content.email,
                    empresa: response.data.content.nomeEmpresa,
                    cnpj: response.data.content.cnpjEmpresa,
                    idUsuario: response.data.content.idUsuario
                });
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
            });

        promises.push(userInfoRequest);

        const userTypeSelectionRequest = axios.get(defines.apiURL + "/api/Home?userTypeSelection=1", { withCredentials: true })
            .then((response) => {
                toggleVisitante(response.data.content.visitante);
                toggleEmpresa(response.data.content.empresarial);
                toggleTectrol(response.data.content.tectrol);
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
            });

        promises.push(userTypeSelectionRequest);

        const requestFiliais = axios.get(defines.apiURL + "/api/Home?home=1&userTypeRequest=" + userType, { withCredentials: true })
        .then((response) => {
            setFiliais(response.data.content);
        })
        .catch((error) => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
        });

        promises.push(requestFiliais);

        Promise.all(promises)
        .then(() => {
            toggleLoading(false);
        });

    }, [dispatch, navigate]);

    
    const changeUserData = () => {
        toggleSave(true);
    
        // Comparar dados e criar o objeto com as mudanças
        const updatedData: Partial<UsuarioProps> = {};
    
        // Verificar se nome ou sobrenome foram alterados
        const nomeChanged = usuarioUpdate.nome !== usuarioOriginal.nome;
        const sobrenomeChanged = usuarioUpdate.sobrenome !== usuarioOriginal.sobrenome;
    
        // Se o nome ou sobrenome mudaram, atualize nomeCompleto
        if (nomeChanged || sobrenomeChanged) {
            updatedData.nomeCompleto = `${usuarioUpdate.nome} ${usuarioUpdate.sobrenome}`;
        }
    
        // Comparar os outros campos e adicionar ao objeto de mudanças
        Object.keys(usuarioUpdate).forEach(key => {
            if (key !== 'nome' && key !== 'sobrenome' && usuarioUpdate[key as keyof UsuarioProps] !== usuarioOriginal[key as keyof UsuarioProps]) {
                updatedData[key as keyof UsuarioProps] = usuarioUpdate[key as keyof UsuarioProps];
            }
        });
    
        // Verificar se há algo para enviar
        if (Object.keys(updatedData).length > 0) {
            axios.put(defines.apiURL + "/api/UpdateUser?userTypeRequest=" + userType + "&idFilial=" + Functions.ReplaceSpecialCharacters(selectedUnity), { idUsuario: usuarioUpdate.idUsuario, ...updatedData }, { withCredentials: true })
                .then(response => {
                    dispatch({ type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Dados alterados com sucesso' });
                    toggleSave(false);
                    setFieldsChanged(0);

                    setUsuarioOriginal((prev) => ({
                        ...prev,
                        ...updatedData,
                    }));

                })
                .catch(error => {
                    Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
                    toggleSave(false);
                });
        } else {
            toggleSave(false); // Não há mudanças para enviar
        }
    };
    

    const handleClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const compressImage = async (fileUploaded: any) => {
        const options = {
            maxSizeMB: 0.256,
            maxWidthOrHeight: 300,
            useWebWorker: true
        };

        try {
            const compressedImage = await imageCompression(fileUploaded, options);
            const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedImage);

            setUsuarioUpdate(prevState => ({
                ...prevState,
                foto: compressedBase64
            }));

        } catch (error) {
            alert('Não foi possível selecionar essa imagem. Verifique a imagem e tente novamente.');
        }
    };

    useEffect(() => {
        if(loading) return

        toggleLoading(true);
        if (usuarioUpdate.foto && usuarioUpdate.foto !== usuarioOriginal.foto) {
            axios.put(defines.apiURL + "/api/UpdateUser?userTypeRequest=" + userType + "&idFilial=" + Functions.ReplaceSpecialCharacters(selectedUnity), { idUsuario: usuarioUpdate.idUsuario, foto: usuarioUpdate.foto }, { withCredentials: true })
                .then(response => {
                    dispatch({ type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Foto alterada com sucesso' });
                })
                .catch(error => {
                    Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
                    setUsuarioUpdate((prev) => ({ ...prev, foto: usuarioOriginal.foto }));
                });
        }
        toggleLoading(false);
    }, [usuarioUpdate.foto]);

    // Update the state and mark as unsaved changes
    const setNomeUpdate = (nome: string) => {
        setUsuarioUpdate((prev) => ({ ...prev, nome }));
        if(nome !== usuarioOriginal.nome){
            setFieldsChanged(fieldsChanged + 1);
        }
        else{
            setFieldsChanged(fieldsChanged - 1);
        }
    };
    
    const setSobrenomeUpdate = (sobrenome: string) => {
        setUsuarioUpdate((prev) => ({ ...prev, sobrenome }));
        if(sobrenome !== usuarioOriginal.sobrenome){
            setFieldsChanged(fieldsChanged + 1);
        }
        else{
            setFieldsChanged(fieldsChanged - 1);
        }
    };
    
    const setDDDUpdate = (ddd: string) => {
        const dddRegex = /^[0-9]*$/;
        if (dddRegex.test(ddd)) {
            setUsuarioUpdate((prev) => ({ ...prev, ddd }));
            if (ddd !== usuarioOriginal.ddd) {
            setFieldsChanged(fieldsChanged + 1);
            } else {
            setFieldsChanged(fieldsChanged - 1);
            }
        } else {
            dispatch({ type: 'ADD_NOTIFICATION', status: 2, title: 'Formato Inválido!', text: 'Seu DDD deve conter somente números.' });
        }
    };
    
    const setTelefoneUpdate = (celular: string) => {
        const phoneRegex = /^[0-9]*$/;
        if (phoneRegex.test(celular)) {
            setUsuarioUpdate((prev) => ({ ...prev, celular }));
            if (celular !== usuarioOriginal.celular) {
                setFieldsChanged(fieldsChanged + 1);
            } else {
                setFieldsChanged(fieldsChanged - 1);
            }
        } else {
            dispatch({ type: 'ADD_NOTIFICATION', status: 2, title: 'Formato Inválido!', text: 'Seu telefone deve conter somente números.' });
        }
    };
    
    const setEmailUpdate = (email: string) => {
        setUsuarioUpdate((prev) => ({ ...prev, email }));
        if(email !== usuarioOriginal.email){
            setFieldsChanged(fieldsChanged + 1);
        }
        else{
            setFieldsChanged(fieldsChanged - 1);
        }
    };

    return (
        <Body.Container>
            <Sidebar type={userType}
                    content={defines.ContentSidebar.MY_ACCOUNT}
                    fotoPerfil={usuarioUpdate.foto}
            />
            <Body.Content>
                <Body.HalfContent style={{ width: '560px' }}>

                    <PersonalData
                        nome={usuarioUpdate.nome} setNome={setNomeUpdate}
                        sobrenome={usuarioUpdate.sobrenome} setSobrenome={setSobrenomeUpdate}
                        ddd={usuarioUpdate.ddd} setDDD={setDDDUpdate}
                        telefone={usuarioUpdate.celular} setTelefone={setTelefoneUpdate}
                        email={usuarioUpdate.email} setEmail={setEmailUpdate}
                        loadingSave={loadingSave}
                        changeUserData={changeUserData}
                        toggleIsAuth={togglePWD}
                    />

                    {fieldsChanged ? (
                        <Body.TextWarning>Se você realizou alterações, elas ainda não foram salvas e serão descartadas ao sair da página.</Body.TextWarning>
                    ): null}

                    {(userType === defines.TypeSidebar.DEFAULT_USER) &&
                        <CompanyData
                            type={userType}
                            empresa={usuarioUpdate.empresa}
                            cnpj={usuarioUpdate.cnpj}
                            filiais={filiais}
                        />
                    }

                    <br />

                    {(userType === defines.TypeSidebar.DEFAULT_USER || userType === defines.TypeSidebar.TECTROL_USER) && isVisitante &&
                        <ButtonWithoutBG text="Trocar para conta de Usuário Visitante"
                            action={() => navigate('/menu')} />
                    }

                    {userType === defines.TypeSidebar.VISITING_USER &&
                        <div>
                            <ButtonWithoutBG text={"Trocar para conta de usuário" + (isTectrol ? " administrador" : " empresarial")} action={() => navigate('/menu')} />
                        </div>
                    }

                    <ButtonWithoutBG color="red" text="Apagar minha conta" action={() => toggleDelete(true)} />
                </Body.HalfContent>

                <Body.HalfContent style={{ alignItems: 'center' }}>
                    <Body.Photo src={usuarioUpdate.foto || Avator} />
                    <input type="file" style={{ display: 'none' }}
                        ref={hiddenFileInput}
                        accept="image/*"
                        onChange={(e) => compressImage(e.target.files![0])} />
                    <ButtonWithoutBG text="Alterar foto" action={handleClick} />
                </Body.HalfContent>
            </Body.Content>
            {loading && <Loading />}
            {changePWD && <Modals.ChangePWD toggle={togglePWD} idUsuario={usuarioUpdate.idUsuario} />}
            {deleteAccount && <Modals.DeleteAccount toggle={toggleDelete} idUsuario={Functions.ReplaceSpecialCharacters(usuarioUpdate.idUsuario)} />}
        </Body.Container>
    );
}

export default MyAccount;
