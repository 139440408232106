import React, { useEffect, useState } from "react";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import * as Types from '../../../utils/types/Types'
import ButtonWithoutBG from "../Buttons/ButtonWithoutBG/Button";
import MyAccountImg from '../../../assets/images/my-account-image.png'
import ConnectedAppsImg from '../../../assets/images/connected-apps-image.png'
import ManagerUserImg from '../../../assets/images/manager-users-image.png'
import ManagerPermissionImg from '../../../assets/images/manager-permission-image.png'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Avatar from '../../../assets/images/avatar.png'
import Spinner from "../Spinner/Spinner";
import ArrowDownIcon from '../../../assets/icons/arrow-down-icon.svg';
import ButtonWrapper from "../Buttons/ButtonWrapper/ButtonWrapper";
import { State } from "../../redux/store";

type SidebarProps = {
    type: number,
    content: number,
    fotoPerfil?: string,
}

const Sidebar: React.FC <SidebarProps> = ({
    content, type, fotoPerfil
}) => {
    const acessLevel = useSelector((state: State) => state.acessLevel.acessLevel);
    const userType = useSelector((state: State) => state.userType.userType);
    const selectedUnity = useSelector((state: State) => state.selectedUnity.selectedUnity);
    const [nome, setNome] = useState('')
    const [nomeEmpresa, setNomeEmpresa] = useState('')
    const [nomeFilial, setNomeFilial] = useState('')
    const [foto, setFoto] = useState('')
    const [loading, toggleLoading] = useState(true)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [hasMultipleUnities, setHasMultipleUnities] = useState(false)

    useEffect(()=>{
        let requests = []


        const request1 = axios.get(defines.apiURL+"/api/Home?sidebarInfo=1&userTypeRequest=" + type, {withCredentials: true})
        .then((response)=> {
            setNome(response.data.content.nome)
            setNomeEmpresa(response.data.content.nomeEmpresa)
            setNomeFilial(response.data.content.nomeFilial)
            response.data.content.fotoUsuario ? setFoto(response.data.content.fotoUsuario) : setFoto('')
        })
        .catch((error)=>{ 
            if(["ACS-11", "DSS-1", "DSS-2", "ACS-4", "ACS-6", "ACS-7"].includes(error.response.data.code)){
                return
            }
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
        })

        requests.push(request1)

        const request2 = axios.get(`${defines.apiURL}/api/Home?currentUserInfo=1&userTypeRequest=${type}&idFilial=${Functions.ReplaceSpecialCharacters(selectedUnity)}`, { withCredentials: true })
        .then((response) => {
            setNomeFilial(response.data.content.nomeFilial);
            if (userType === defines.TypeSidebar.VISITING_USER) {
                dispatch({type: 'SET_ACESS_LEVEL', acessLevel: response.data.content.nivelAcesso});
            } else if (userType === defines.TypeSidebar.DEFAULT_USER || userType === defines.TypeSidebar.TECTROL_USER) {
                dispatch({type: 'SET_ACESS_LEVEL', acessLevel: response.data.content.cargo});
            }
        })
        .catch((error) => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
        });

        requests.push(request2)

        const request3 = axios.get(defines.apiURL +"/api/Home?home=1&userTypeRequest=" + userType, { withCredentials: true })
        .then((response) => {
            if(response.data.content.length > 1){
                setHasMultipleUnities(true)
            }
        })
        .catch((error) => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
        });

        requests.push(request3)

        Promise.all(requests).finally(() => {
            toggleLoading(false);
        });

    }, [dispatch, navigate, selectedUnity])

    const getImage = () => {
        switch(content){
            case defines.ContentSidebar.INVITES:
            case defines.ContentSidebar.MY_ACCOUNT:
                return MyAccountImg
            case defines.ContentSidebar.CONNECTED_APPS:
            case defines.ContentSidebar.APPS_AND_PLANS:
                return ConnectedAppsImg
            case defines.ContentSidebar.MANAGE_USERS:
                return ManagerUserImg
            case defines.ContentSidebar.MANAGE_PERMISSIONS:
            case defines.ContentSidebar.MANAGE_COMPANY:
                return ManagerPermissionImg
        }
    }

    const getUserImage = () => {
        if (fotoPerfil) {
            return fotoPerfil;
        }
        if (foto) {
            return foto;
        }
        return Avatar;
    }

    const getNome = () => {
        if(nome){
            return nome.split(' ')[0]
        }
        return ''
    }

    const getNomeEmpresa = () => {
        return nomeEmpresa || ''
    }

    const getNomeFilial = () => {
        return nomeFilial || '';
    }

    const getCargo = () => {
        switch (userType) {
            case defines.TypeSidebar.TECTROL_USER:
            return ("Tectrol " + Object.keys(defines.CargoTectrol).find(key => defines.CargoTectrol[key as keyof typeof defines.CargoTectrol] === acessLevel)) || 'Tectrol';
            case defines.TypeSidebar.VISITING_USER:
            return (Object.keys(defines.CargoVisitante).find(key => defines.CargoVisitante[key as keyof typeof defines.CargoVisitante] === acessLevel)) || 'Visitante';
            case defines.TypeSidebar.DEFAULT_USER:
            return ("Empresarial " + Object.keys(defines.CargoEmpresarial).find(key => defines.CargoEmpresarial[key as keyof typeof defines.CargoEmpresarial] === acessLevel)) || 'Empresarial';
            default:
            return 'Cargo Indisponível';
        }
    }

    const logout = () => {
        axios.get(defines.apiURL+"/api/Logout",  {withCredentials: true})
            .then((response)=>{
                localStorage.removeItem("@logged")
                localStorage.removeItem("persist:root")
                navigate('/')
            })
            .catch((error: any)=>{
                if(error.response.data.code === 'ACS-9'){
                    navigate('/')
                    return
                }
                dispatch({type: 'ADD_NOTIFICATION', title: "Erro", status: 3, text: error.response.data.message})
            })
            .finally(() => {    
                localStorage.removeItem("@logged")
                localStorage.removeItem("persist:root")
            })
    }

    if(loading){
        return <Body.Card>
                    <Spinner size={30} blue/>
                </Body.Card>
    }

    return <Body.Card >
            <Body.ProfileCard>
                <Body.Photo src={getUserImage()} />
                <Body.ProfileInfo>
                    <Body.Row>
                        <Body.Title>Olá, <strong>{getNome()}</strong></Body.Title>
                    </Body.Row>

                    { type === defines.TypeSidebar.TECTROL_USER ? (
                        <>
                        <Body.Subtitle>{getCargo()}</Body.Subtitle>
                        </>
                    ): null}
                    { type === defines.TypeSidebar.VISITING_USER ? (
                        <>
                        <Body.Subtitle>Usuário Visitante</Body.Subtitle>
                        </>
                    ): null}
                    { type === defines.TypeSidebar.DEFAULT_USER ? (
                        <>
                        <Body.Subtitle>{getNomeEmpresa()}</Body.Subtitle>
                        { hasMultipleUnities ? (
                            <ButtonWrapper
                            style={{ width: '120px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            action={() => {dispatch({ type: 'REQ_UNITY' })}}>
                                <Body.UnityBadge>
                                    <a>{getNomeFilial()}</a>
                                    <Body.BadgeIcon src={ArrowDownIcon} />
                                </Body.UnityBadge>
                            </ButtonWrapper>
                        ): <Body.Subtitle>{getNomeFilial()}</Body.Subtitle>}
                        <Body.Subtitle>{getCargo()}</Body.Subtitle>
                        </>
                    ): null}

                </Body.ProfileInfo>
            </Body.ProfileCard>



            <Body.Content>
                <ButtonWithoutBG text="Minha Conta" action={() => navigate('/minha-conta')} size={14}
                    active={content === defines.ContentSidebar.MY_ACCOUNT}/>
                
                <Body.BottomBar />

                {type === defines.TypeSidebar.VISITING_USER && (
                    <>
                        <ButtonWithoutBG text="Apps Conectados" action={() => {navigate('/apps-conectados-visitante')}} size={14}
                            active={content === defines.ContentSidebar.CONNECTED_APPS}/>
                        <Body.BottomBar />
                        <ButtonWithoutBG text="Convites" action={() => {navigate('/convites-visitante')}} size={14}
                            active={content === defines.ContentSidebar.INVITES}/>
                        <Body.BottomBar />
                </>
                )}

                {type === defines.TypeSidebar.DEFAULT_USER && (
                    <>
                        <ButtonWithoutBG text="Apps Conectados" action={() => {navigate('/apps-conectados')}} size={14}
                            active={content === defines.ContentSidebar.CONNECTED_APPS}/>
                        <Body.BottomBar />
                        { acessLevel === defines.CargoEmpresarial['Administrador'] ?
                            <>
                                <ButtonWithoutBG text="Gerenciar Usuários" action={() => { navigate('/empresarial') } } size={14}
                                    active={content === defines.ContentSidebar.MANAGE_USERS} />
                                <Body.BottomBar />
                            </>: null
                        }
                    </>
                )}

                {type === defines.TypeSidebar.TECTROL_USER && (
                    <>
                        <ButtonWithoutBG text="Apps e Planos" action={() => navigate('/aplicativos')} size={14}
                            active={content === defines.ContentSidebar.APPS_AND_PLANS}/>
                        <Body.BottomBar />

                        {acessLevel === defines.CargoTectrol.Suporte ? 
                            <>
                                <ButtonWithoutBG text="Gerenciar Usuários" action={() => { navigate('/tectrol'); } } size={14}
                                    active={content === defines.ContentSidebar.MANAGE_USERS} />
                                <Body.BottomBar />
                            </>:
                        null}

                        <ButtonWithoutBG text="Gerenciar Empresas" action={() => {navigate('/empresas')}} size={14}
                            active={content === defines.ContentSidebar.MANAGE_COMPANY}/>
                        <Body.BottomBar />
                    </>
                )}

                <ButtonWithoutBG text="Sair" action={()=>logout()} size={14}/>
            </Body.Content>

            <Body.Image src={getImage()} />
    </Body.Card>
}

export default Sidebar