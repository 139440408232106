import React, { useEffect, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import Input from "../../components/Inputs/Input/Input";
import * as Types from '../../../utils/types/Types'
// import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
// import imageCompression from "browser-image-compression";
import Button from "../../components/Buttons/Button/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import * as Modals from './modals/Modals'
import { State } from "../../redux/store";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";

type PersonalDataProps = {
    nome: string
    setNome: (nome: string) => void,
    sobrenome: string
    setSobrenome: (sobrenome: string) => void,
    ddd: string
    setDDD: (ddd: string) => void,
    telefone: string
    setTelefone: (telefone: string) => void,
    email: string
    setEmail: (email: string) => void,
    loadingSave: boolean,
    changeUserData: () => void,
    toggleIsAuth: (value: boolean) => void,
}

const PersonalData: React.FC <PersonalDataProps> = ({
    nome, setNome,
    sobrenome, setSobrenome,
    ddd, setDDD,
    telefone, setTelefone,
    email, setEmail,
    loadingSave,
    changeUserData,
    toggleIsAuth,
}) => {

    const [errorEmail, setErrorEmail] = useState(false)

    const auxSetEmail = (value: string) => {
        if (Functions.verifyEmail(value)) {
            setEmail(value)
            setErrorEmail(false)
        } else {
            setEmail(value)
            setErrorEmail(true)
        }
    }

    return <>
            <Body.Title>DADOS PESSOAIS</Body.Title>
            <Body.Row>
                <Input width={140} text={nome} setText={setNome} placeholder="Nome" title="Nome" />
                <Input width={400} text={sobrenome} setText={setSobrenome} placeholder="Sobrenome" title="Sobrenome" />
            </Body.Row>
            <Body.Row>
                <Input width={260} text={email} setText={auxSetEmail} placeholder="Email" title="Email" />
                <Input width={60} text={ddd} setText={setDDD} placeholder="DDD" title="DDD" limit={3}/>
                <Input width={200} text={telefone} setText={setTelefone} placeholder="Telefone" title="Telefone" limit={9} />
            </Body.Row>
            {errorEmail?<Body.TextError>
                Formato de email inválido ex: email@email.com</Body.TextError>:null}

            <br />
            <Body.Row>
                <Button text="Salvar" color="blue" action={()=>changeUserData()}
                    loading={loadingSave} width={150}/>
                <Button text="Alterar senha" action={()=>toggleIsAuth(true)} width={150}/>
            </Body.Row>
    </>
}

export default PersonalData