import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Input from "../../../components/Inputs/Input/Input";
import Modal from "../../../components/Modals/Modal/Modal";
import * as Types from '../../../../utils/types/Types'
import * as Body from '../Styles'
import * as defines from '../../../../utils/defines/Defines'
import * as Functions from '../../../../utils/functions/Functions'
import Button from "../../../components/Buttons/Button/Button";
import { State } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AcessLevelProps } from "../../../redux/acessLevel/actionsType";
import axios from "axios";
import { verifyEmail } from "../../../../utils/functions/Functions";
import { useNavigate } from "react-router-dom";
import SearchIcon from '../../../../assets/icons/Search.svg'

type ModalProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    action: any
}

type SelectUnitProps = {
    setId: Dispatch<SetStateAction<string>>
    setNome: Dispatch<SetStateAction<string>>
    toggle: Dispatch<SetStateAction<boolean>>
}

const SelectUnit: React.FC <SelectUnitProps> = ({
    setId, setNome, toggle
}) => {

    const [loading, toggleLoading] = useState(true)
    const [filiais, setFiliais] = useState<Types.Unidade[]>([])
    const [filteredFiliais, setFiltered] = useState<Types.Unidade[]>([])
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/FilialCRUD",  {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
                setFiliais(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    },[dispatch, navigate])

    const select = (id: string, nome: string) => {
        setId(id)
        setNome(nome)
        toggle(false)
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(filiais)
            return
        }

        const filtered = filiais.filter(item => item.nomeFilial.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered)
    }

    return <Modal title="Selecionar unidade" toggleModal={toggle} loading={loading}>
        <>
        <Body.FindContent style={{width: '100%'}}>
                <Body.Find onChange={e => filterData(e.target.value)} style={{maxWidth: '100%', width: '100%'}}/> 
                <Body.Icon src={SearchIcon} />       
        </Body.FindContent>
        <Body.Scroll>
            {filteredFiliais.map((item, index)=>{
                return <Body.SelectUser key={index.toString()}
                onClick={()=>select(item.idFilial, item.nomeFilial)}>
                    <Body.Text>{item.nomeFilial}</Body.Text>
                </Body.SelectUser>
            })}
        </Body.Scroll>
        </>
    </Modal>
}

export const CreateUser: React.FC <ModalProps> = ({
    toggle, action
}) => {

    const [nomeCompleto, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setTelefone] = useState('')
    const [nivelAcesso, toggleNivel] = useState(1)
    const [idFilial, setIdFilial] = useState('')
    const [dpo, toggleDPO] = useState("0")

    const [hasLenght, toggleLength] = useState(false)
    const [hasUpper, toggleUpper] = useState(false)
    const [hasLower, toggleLower] = useState(false)
    const [hasSpecial, toggleSpecial] = useState(false)

    useEffect(()=>{
        const verify = Functions.checkPasswordRestrictions(senha)

        toggleLength(verify.lenght)
        toggleLower(verify.lowercase)
        toggleUpper(verify.uppercase)
        toggleSpecial(verify.special)
    }, [senha])

    const getColor = (verify: boolean) => {
        if(verify){
            return "green"
        }
        return "red"
    }

    const [loading, toggleLoading] = useState(false)

    const [nomeFilial, setNomeFilial] = useState('')
    const [modalSelect, toggleSelect] = useState(false)
    const acesso: AcessLevelProps = useSelector((state: State) => state.acessLevel)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tryCreate = () => {

        if(nomeCompleto.length === 0 || !verifyEmail(email) || senha.length < 12 ||
            ddd.length !== 2  || celular.length !== 9){
            dispatch({type: 'ADD_NOTIFICATION', title: 'Erro', text: 'Preencha todos os campos corretamente',
            status: 3})
            return
         }

         toggleLoading(true)
         axios.post(defines.apiURL+"/api/UsuarioCRUD", {
            email, senha,nomeCompleto, ddd, celular, nivelAcesso, idFilial, encarregadoDados: dpo
         }, {withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, text: 'Usuário criado com sucesso'})
                toggleLoading(false)
                action()
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
         
    }


    return <>
    <Modal toggleModal={toggle} title="Adicionar usuário">
        <>
        <Body.Scroll style={{width: '400px', 
            gridGap: '10px 0px', marginBottom: '10px'}}>        
            <Input text={nomeCompleto} setText={setNome} title="Nome completo" />
            <div style={{height: '10px'}} />
            <Input text={email} setText={setEmail} title="Email" />
            <div style={{height: '10px'}} />
            <Input text={senha} setText={setSenha} title="Senha" password={true}/>
            <div style={{height: '10px'}} />
            <Body.Text style={{fontWeight: '300', fontSize: 14}}>
                Sua senha deve conter letras <strong style={{color: getColor(hasUpper)}}>maiúsculas</strong>
                , <strong style={{color: getColor(hasLower)}}>minúsculas</strong>
                , <strong style={{color: getColor(hasSpecial)}}>caracteres especiais (ex: @#$)
                </strong> e no minimo<strong style={{color: getColor(hasLenght)}}> 12 caracteres</strong></Body.Text>
            <div style={{height: '10px'}} />
            <Body.Row style={{width: '400px', justifyContent: 'space-between'}}>
                <Input text={ddd} setText={setDDD} title="DDD"
                        limit={2} width={80} />
                <Input text={celular} setText={setTelefone} title="Telefone"
                        limit={9} width={310} />
            </Body.Row>
            <div style={{height: '10px'}} />
            <Body.SubTitle style={{marginBottom: '5px'}}>
                Nivel de acesso</Body.SubTitle>
            <Body.Row>
                <Body.CardInput onClick={()=>toggleNivel(defines.NivelAcesso.TECNICO)}>
                    <input type="radio"
                        checked={nivelAcesso === defines.NivelAcesso.TECNICO}/>
                    <Body.Text>Técnico</Body.Text>
                </Body.CardInput>
                <Body.CardInput onClick={()=>toggleNivel(defines.NivelAcesso.ADMINISTRADOR)}>
                    <input type="radio"
                        checked={nivelAcesso === defines.NivelAcesso.ADMINISTRADOR}/>
                    <Body.Text>Administrador</Body.Text>
                </Body.CardInput>
                {acesso.acessLevel === defines.NivelAcesso.TECTROL?
                 <Body.CardInput onClick={()=>toggleNivel(defines.NivelAcesso.TECTROL)}>
                    <input type="radio"
                        checked={nivelAcesso === defines.NivelAcesso.TECTROL}/>
                    <Body.Text>Tectrol</Body.Text>
                </Body.CardInput>:null
                }
            </Body.Row>
            {nivelAcesso !== defines.NivelAcesso.TECTROL?
            <><div style={{height: '10px'}} />
            <Body.SubTitle style={{marginBottom: '5px'}}>
                Selecione uma unidade</Body.SubTitle>
            <Body.CardUnit onClick={()=>toggleSelect(true)}>
                <Body.TextUnit>
                    {nomeFilial}
                </Body.TextUnit>
            </Body.CardUnit></>:null}
            <div style={{height: '10px'}} />
            <Body.SubTitle style={{marginBottom: '5px'}}>
                Permissões</Body.SubTitle>
            <Body.CardInput onClick={()=>toggleDPO(dpo === "0"?"1":"0")}>
                <input type="radio"
                    checked={dpo === "1"}/>
                <Body.Text>Gerente de dados</Body.Text>
            </Body.CardInput>
        </Body.Scroll>
        <Button text="Criar" action={()=>tryCreate()} loading={loading}/>
        </>
    </Modal>
    {modalSelect?<SelectUnit setId={setIdFilial} toggle={toggleSelect}
        setNome={setNomeFilial}/>:null}
    </>
}

type UnitProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    idUsuario: string,
    email: string,
    nome: string,
}

type ActionUserUnitProps = {
    nome: string,
    unidade: string,
    email: string,
    idFilial?: string,
    idPertenceFilial?: string,
    nivelAcesso?: number,
    toggle: any,
    sent: any,
}

const RemoveUserUnit: React.FC<ActionUserUnitProps> = ({
    nome, unidade, toggle, nivelAcesso, sent, idPertenceFilial
}) => {

    const [loading, toggleLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const tryRemoveAdm = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL+"/api/UsuarioAdministraFilialCRUD?idAdministraFilial="+
            Functions.ReplaceSpecialCharacters(idPertenceFilial!),  {withCredentials: true})
            .then((response) => {
                sent()
                toggle(false)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    const tryRemoveTecnico = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL+"/api/UsuarioPertenceFilialCRUD?idPertenceFilial="+
            Functions.ReplaceSpecialCharacters(idPertenceFilial!), {withCredentials: true})
            .then((response) => {
                sent()
                toggleLoading(false)
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    return <Modal title="">
        <div style={{display: 'flex', flexDirection: 'column',
            alignItems: 'center'}}>
            <Body.Text style={{marginBottom: '20px'}}>
                Remover <strong>{nome}</strong> da unidade 
                <strong> {unidade}</strong></Body.Text>
            <Body.Row>
                <Button text="Remover" width={200} color="red" loading={loading}
                    action={nivelAcesso === defines.NivelAcesso.TECNICO?()=>
                        tryRemoveTecnico():()=>tryRemoveAdm()} />
                <Button text="Cancelar" width={200} action={toggle} disabled={loading} />
            </Body.Row>
        </div>
    </Modal>
}

const AddUserUnit: React.FC <ActionUserUnitProps> = ({
    nome, unidade, toggle, sent, email, idFilial
}) => {

    const [loading, toggleLoading] = useState(false)
    const [loadingTec, toggleLoadingTec] = useState(false)
    const [dpo, toggleDPO] = useState(false)
    const navigate = useNavigate()
    
    const dispatch = useDispatch()

    const trySendAdm = () => {
        toggleLoading(true)
        axios.post(defines.apiURL+"/api/UsuarioAdministraFilialCRUD", {
                email, idFilial, encarregadoDados: Number(dpo)}, {withCredentials: true})
            .then((response) => {
                sent()
                toggleLoading(false)
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    const trySendTecnico = () => {
        toggleLoadingTec(true)
        axios.post(defines.apiURL+"/api/UsuarioPertenceFilialCRUD", {
            email, idFilial, encarregadoDados: Number(dpo)}, {withCredentials: true})
            .then((response) => {
                sent()
                toggleLoadingTec(false)
                toggle(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoadingTec(false)
            })
    }

    return <Modal title="" toggleModal={toggle}>
        <div style={{display: 'flex', flexDirection: 'column',
            alignItems: 'center'}}>
        <Body.Text style={{marginBottom: '20px'}}>
            Adicionar <strong>{nome}</strong> a unidade 
            <strong> {unidade}</strong></Body.Text>
        <Body.Row style={{marginBottom: '15px', alignItems: 'center'}}>
            <Body.CardInput onClick={()=>toggleDPO(!dpo)}>
                <input type="radio"
                    checked={dpo}/>
                <Body.Text>Gerente de dados</Body.Text>
            </Body.CardInput>
        </Body.Row>
        <Body.Row>
            <Button text="Administrador" width={200} action={()=>trySendAdm()}
                loading={loading} disabled={loadingTec} />
            <Button text="Técnico" width={200} action={()=>trySendTecnico()}
                loading={loadingTec} disabled={loading} />
        </Body.Row>
    </div>
</Modal>
}

export const ChangeUnit: React.FC <UnitProps> = ({
    toggle, idUsuario, nome, email
}) => {
    const [parte, setParte] = useState<Types.Unidade[]>([])
    const [naoFazParte, setNaoFaz] = useState<Types.Unidade[]>([])
    const [filterParte, setFilterParte] = useState<Types.Unidade[]>([])
    const [filterNaoFazParte, setFilterNaoFaz] = useState<Types.Unidade[]>([])
    const [loading, toggleLoading] = useState(true)
    const [unidade, setUnidade] = useState("")
    const [idFilial, setId] = useState('')
    const [idPertenceFilial, setIdPertence] = useState('')
    const [invited, setInvited] = useState<string[]>([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const [modalRemove, toggleRemove] = useState(false)
    const [modalAdd, toggleAdd] = useState(false)

    const getData = () => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/ParticipationList?userUnavailableBranches="+idUsuario,
            {withCredentials: true})
            .then((response)=>{
                setParte(response.data.content)
                setFilterParte(response.data.content)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })

        axios.get(defines.apiURL+"/api/ParticipationList?userAvailableBranches="+idUsuario,
            {withCredentials: true})
            .then((response)=>{
                setNaoFaz(response.data.content)
                setFilterNaoFaz(response.data.content)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })

    }

    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/ParticipationList?userUnavailableBranches="+idUsuario,
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
                setParte(sorted)
                setFilterParte(sorted)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })

        axios.get(defines.apiURL+"/api/ParticipationList?userAvailableBranches="+idUsuario,
            {withCredentials: true})
            .then((response)=>{
                const sorted = Functions.SortResponse(response.data.content, "nomeFilial")
                setNaoFaz(sorted)
                setFilterNaoFaz(sorted)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, navigate, idUsuario])

    const sent = () => {
        dispatch({type: 'ADD_ALERT', title: 'Convite enviado', text: 'O convite irá expirar em 2 minutos'})
        setInvited(old => [...old, unidade])
    }

    const removed = () => {
        dispatch({type: 'ADD_NOTIFICATION', title: 'Removido com sucesso', status: 1})
        getData()
    }

    const tryRemove = (text: string, idPertenceFilial: string) => {
        setUnidade(text)
        setIdPertence(idPertenceFilial)
        toggleRemove(true)
    }

    const tryAdd = (text: string, idFilial: string) => {
        setUnidade(text)
        setId(idFilial)
        setInvited(old => [...old, idFilial])
        toggleAdd(true)
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFilterNaoFaz(naoFazParte)
            setFilterParte(parte)
            return
        }
        const filtered = parte.filter(item => item.nomeFilial.toUpperCase().includes(name.toUpperCase()))
        setFilterParte(filtered!)
        const usersFiltered = naoFazParte.filter(item => item.nomeFilial.toUpperCase().includes(name.toUpperCase()))
        setFilterNaoFaz(usersFiltered!)
    }

    return <>
    <Modal title="Unidades" toggleModal={toggle} loading={loading}>
        <>
        <Body.FindContent style={{width: '100%'}}>
                <Body.Find onChange={e => filterData(e.target.value)} style={{maxWidth: '100%', width: '100%'}}/> 
                <Body.Icon src={SearchIcon} />       
        </Body.FindContent>
        <Body.Scroll>
            {(filterParte.length === 0 && filterNaoFazParte.length ===0) &&
                <Body.Row>
                    <Body.Text>Não foram encontradas unidades disponíveis</Body.Text>
                </Body.Row>
            }

            {filterParte.map((item, index) => {
                return <Body.Row key={index} 
                    style={{marginBottom: '10px', alignItems: 'center'}}>
                    <Body.Text style={{width: '300px'}}>
                        {item.nomeFilial}
                    </Body.Text>
                    <Button text="Remover" action={()=>tryRemove(item.nomeFilial, item.idAdministra!)}
                        color="red" width={100}/>
                </Body.Row>
            })}
            {filterNaoFazParte.map((item, index) => {
                return <Body.Row key={index} style={{marginBottom: '10px', alignItems: 'center'}}>
                    <Body.Text style={{width: '300px'}}>
                        {item.nomeFilial}
                    </Body.Text>
                    <Button text={invited.includes(item.idFilial)?"Convidado":"Convidar"}
                        disabled={invited.includes(item.idFilial)} 
                        action={()=>tryAdd(item.nomeFilial, item.idFilial)} width={100}/>
                </Body.Row>
            })}            
        </Body.Scroll>
        </>
    </Modal>
    {modalRemove?<RemoveUserUnit sent={removed} nome={nome} unidade={unidade}
        idPertenceFilial={idPertenceFilial}  toggle={()=>toggleRemove(false)}
        email={email} />:null}
    {modalAdd?<AddUserUnit sent={sent} nome={nome} unidade={unidade} 
        toggle={()=>toggleAdd(false)} email={email} idFilial={idFilial} />:null}
    </>
}

type ModalDeleteProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    idUsuario: string,
    nome: String,
    action: any,
    nomeFilial?: string
}

export const ModalDeleteUser: React.FC <ModalDeleteProps> = ({
    toggle, idUsuario, nome, action, nomeFilial= 'atual'
}) => {

    return <Modal title="Desvincular Usuário">
            <div style={{display: 'flex', flexDirection: 'column',
                alignItems: 'center'}}>
                <Body.Text style={{marginBottom: '20px'}}>
                    Tem certeza que deseja desvincular o usuário <strong>{nome}</strong>?
                    <br/>Essa ação removerá o usuário da filial <strong>{nomeFilial}</strong>.
                </Body.Text>
                <Body.Row>
                    <Button text="Desvincular" width={200} color="red" action={()=>action()} />
                    <Button text="Cancelar" width={200} action={()=>toggle(false)} />
                </Body.Row>
            </div>
        </Modal>
}