import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Input from "../../../components/Inputs/Input/Input";
import Modal from "../../../components/Modals/Modal/Modal";
import * as Body from '../Styles';
import Button from "../../../components/Buttons/Button/Button";
import ButtonWrapper from "../../../components/Buttons/ButtonWrapper/ButtonWrapper";
import AdministradorIcon from '../../../../assets/icons/adm-icon.svg';
import EngenheiroIcon from '../../../../assets/icons/usuario-icon.svg';
import * as Functions from  '../../../../utils/functions/Functions'
import * as defines from  '../../../../utils/defines/Defines'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from '../../../../assets/icons/Search.svg'
import DatetimeInput from "../../../components/DatetimeInput/DatetimeInput";
import { State } from "../../../redux/store";
import * as Types from '../../../../utils/types/Types'


type FilialProps = {
    idFilial: string;
    nomeFilial: string;
    cnpjFilial: string;
    statusAssinatura: number;
    idEmpresa: string;
    cnpjEmpresa: string;
    nomeEmpresa: string;
}

type UsuarioProps = {
    idEmpresarial: string,
    idUsuario: string,
    fotoUsuario: string | null,
    ddd: string,
    celular: string,
    nomeCompleto: string,
    email: string,
    cargo: number,
    encarregadoDados: number
}


type SelectUnitProps = {
    toggle: Dispatch<SetStateAction<boolean>>
    usuario: UsuarioProps
    nomeFilial: string
    idFilial: string
    regenerate: () => void
}

const DeleteUser: React.FC<SelectUnitProps> = ({
    toggle, usuario, nomeFilial, idFilial, regenerate
}) => {
    const userType = useSelector((state: State) => state.userType.userType);

    const [loading, toggleLoading] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const handleDelete = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL + "/api/EmpresarialCRUD?userTypeRequest=" + userType + "&id=" + Functions.ReplaceSpecialCharacters(usuario.idEmpresarial) + "&idFilial=" + Functions.ReplaceSpecialCharacters(idFilial), { withCredentials: true })
            .then((response) => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, text: 'Usuário excluido com sucesso', title: ''})
            toggleLoading(false)
            toggle(false)
            regenerate()
            })
            .catch((error) => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
            })
    }

    useEffect(()=>{
      
    }, [navigate, dispatch])

    return (
        <Modal title={"Retirada de direitos de acesso "} toggleModal={toggle} loading={loading}>
            <div style={{ display: 'flex', flexDirection: 'column', gridGap: '15px 0px' }}>

                <Body.Text>
                    Você tem certeza que gostaria de retirar os direitos de acesso de
                    <span style={{ color: defines.BlueTec }}>&nbsp;{usuario.nomeCompleto}&nbsp;</span>
                    da filial
                    <span style={{ color: defines.BlueTec }}>&nbsp;{nomeFilial}</span>?
                </Body.Text>
                <br />
                <Body.Row>
                    <Button text="Cancelar" action={() => {toggle(false)}} />
                    <Button color="red" text="Deletar" action={() => {handleDelete()}} />
                </Body.Row>
            </div>
        </Modal>
    );
}

export default DeleteUser;
