import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import Input from "../../components/Inputs/Input/Input";
import * as Types from '../../../utils/types/Types'
// import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
// import imageCompression from "browser-image-compression";
import Button from "../../components/Buttons/Button/Button";
import CodeInput from "../../components/Inputs/InputCode/Input";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
// import * as Modals from './modals/Modals'
import { State } from "../../redux/store";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";
import TectrolLogo from '../../../assets/icons/tectrol-logo.svg'
import DinamoLogo from '../../../assets/icons/dinamo-logo.svg'

const Content = {
    Cadastro: 1,
    TFA: 2,
    AccountCreated: 3
}

type CadastroProps = {
    nome: string,
    setNome: Dispatch<SetStateAction<string>>,
    sobrenome: string,
    setSobrenome: Dispatch<SetStateAction<string>>,
    ddd: string,
    setDDD: Dispatch<SetStateAction<string>>,
    telefone: string,
    setTelefone: Dispatch<SetStateAction<string>>,
    senha: string,
    setSenha: Dispatch<SetStateAction<string>>,
    toggleContent: Dispatch<SetStateAction<number>>,
    trySendCadastro: () => void
}

const Cadastro: React.FC<CadastroProps> = ({
    nome, setNome,
    sobrenome, setSobrenome,
    ddd, setDDD,
    telefone, setTelefone,
    senha, setSenha,
    toggleContent,
    trySendCadastro
    }) => {

    const [errorDDD, toggleErrorDDD] = useState(false)
    const [errorPhone, toggleErrorPhone] = useState(false)
    const [errorPass, toggleErrorPass] = useState(false)
    const [loading, toggleLoading] = useState(false)

    const checkInput = () => {
        toggleErrorDDD(false)
        toggleErrorPhone(false)
        toggleErrorPass(false)
        const hasLetters = (str: string) => /[a-zA-Z]/.test(str);

        if(ddd.length !== 2 || hasLetters(ddd)){
            toggleErrorDDD(true)
        }
        if(telefone.length > 11 || hasLetters(telefone)){
            toggleErrorPhone(true)
        }
        const hasUpperCase = (str: string) => /[A-Z]/.test(str);
        const hasLowerCase = (str: string) => /[a-z]/.test(str);
        const hasSpecialChar = (str: string) => /[!@#$%^&*(),.?":{}|<>]/.test(str);

        if(senha.length < 12 || !hasUpperCase(senha) || !hasLowerCase(senha) || !hasSpecialChar(senha)){
            toggleErrorPass(true)
        }
        return
    }


    return <Body.CardLogin>
        <Body.SubTitle>Convite para Entrar</Body.SubTitle>
        <Body.Text>Insira seus dados para criação da conta.</Body.Text>
        <Body.Row>
            <Input width={150} text={nome} setText={setNome} placeholder="Nome" />
            <Input width={210} text={sobrenome} setText={setSobrenome} placeholder="Sobrenome" />
        </Body.Row>
        <Body.Row>
        <Input width={60} text={ddd} setText={setDDD} placeholder="DDD" error={errorDDD} action={() => checkInput()}/>
        <Input width={300} text={telefone} setText={setTelefone} placeholder="Telefone" error={errorPhone} action={() => checkInput()} />
            {errorDDD?<Body.TextError>Formato de DDD inválido ex: 43</Body.TextError>:null}
            {errorPhone?<Body.TextError>Formato de telefone inválido ex: 91234-5678</Body.TextError>:null}
        </Body.Row>
        <Input text={senha} setText={setSenha} placeholder="Senha" password={true} error={errorPass} action={() => checkInput()} />
            {errorPass?<Body.TextError>A senha deve conter letras maiusculas, minusculas, caractéres especiais e possuir no mínimo 12 caracteres.</Body.TextError>:null}
        <Button text="Cadastrar" action={() => trySendCadastro()} />
        </Body.CardLogin>
}

type TFAProps = {
    email: string,
    setEmail: Dispatch<SetStateAction<string>>,
    tfa: string,
    setTFA: Dispatch<SetStateAction<string>>,
    trySendTFA: () => void
}

const TFA: React.FC<TFAProps> = ({
    email, setEmail,
    tfa, setTFA,
    trySendTFA
}) => {


    const [errorEmail, toggleErrorEmail] = useState(false)

    const checkEmail = () => {
        toggleErrorEmail(!Functions.verifyEmail(email))
    }

    return <Body.CardLogin>
        <Body.SubTitle>Convite para Entrar</Body.SubTitle>
        <Body.Text>
                Para criar uma conta, digite abaixo o código que você recebeu em seu e-mail.
            </Body.Text>
        <Input title="Email" text={email} setText={setEmail} placeholder="Email" error={errorEmail} action={() => checkEmail()} />
        {errorEmail?<Body.TextError>
            Formato de email inválido ex: email@email.com</Body.TextError>:null}
        <CodeInput title="Código de Cadastro" setText={setTFA} action={() => {}}/>
        <br />
        <Button text="Enviar" action={trySendTFA} />
    </Body.CardLogin>
}

const AccountCreated: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return <Body.CardLogin>
        <Body.SubTitle>Cadastro Completo</Body.SubTitle>
        <Body.Text>
            Sua conta foi criada com sucesso, clique abaixo para acessar.
        </Body.Text>
        <Button text="Acessar" action={() => navigate('/')} />
    </Body.CardLogin>
}

const CreateVisitingUser: React.FC = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [email, setEmail] = useState('')
    const [tfa, setTFA] = useState('')
    const [nome, setNome] = useState('')
    const [sobrenome, setSobrenome] = useState('')
    const [ddd, setDDD] = useState('')
    const [telefone, setTelefone] = useState('')
    const [senha, setSenha] = useState('')

    const [content, setContent] = useState(Content.TFA)


    useEffect(() => {
        axios.get(defines.apiURL+"/api/Logout",  {withCredentials: true})
        .then((response)=>{
        })
        .catch((error: any)=>{
            if(error.response.data.code === 'ACS-9'){
                return
            }
        })
        .finally(() => {
            localStorage.removeItem("persist:root")
            localStorage.removeItem("@logged")
        })
    }, [dispatch, navigate])

    const trySendTFA = () => {
        axios.post(defines.apiURL+"/api/UsuarioCadastro?firstStep=1", {
            tfaCode: tfa,
            email: email
        }, {withCredentials: true})
        .then((response)=>{
            setContent(Content.Cadastro)
        })
        .catch((error)=>{
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
        })
    }

    const trySendCadastro = () => {
        const nomeCompleto = nome + " " + sobrenome
        axios.post(defines.apiURL+"/api/UsuarioCadastro?secondStep=1", {
            nomeCompleto: nomeCompleto,
            ddd: ddd,
            celular: telefone,
            senha: senha
        }, {withCredentials: true})
        .then((response)=>{
            setContent(Content.AccountCreated)
        })
        .catch((error)=>{
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch)
        })
    }

    const getContent = () => {
        switch(content){
            case Content.Cadastro:
                return <Cadastro 
                    nome={nome} setNome={setNome} 
                    sobrenome={sobrenome} setSobrenome={setSobrenome} 
                    ddd={ddd} setDDD={setDDD}
                    telefone={telefone} setTelefone={setTelefone}
                    senha={senha} setSenha={setSenha}
                    toggleContent={setContent}
                    trySendCadastro={trySendCadastro}
                />
            case Content.TFA:
                return <TFA
                email={email} setEmail={setEmail} 
                tfa={tfa} setTFA={setTFA}
                trySendTFA={trySendTFA}
                />
            case Content.AccountCreated:
                return <AccountCreated />
        }
    }

    return <Body.Container>
    <Body.Row>
        <Body.CompanyLogo style={{ height: '23px', marginLeft: '20px' }} src={DinamoLogo} />
        <Body.CompanyLogo src={TectrolLogo} />
    </Body.Row>
    <Body.Title>Conecta</Body.Title>

    {getContent()}

</Body.Container>
}

export default CreateVisitingUser