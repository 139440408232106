import styled, { keyframes } from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const Container = styled.div`
    background: ${defines.BackgroundColor};
    width: 100vw;
    height: 100vh;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: flex-end;
`
export const TitleRow = styled.div`
    display: flex;
    justify-content: flex-start;
    grid-gap: 0px 5px;
`

export const Root = styled.a`
    text-align: left;
    font-family: 'Nunito';
    font-size: 18px;    
    color: #6D7989;
`

export const Page = styled.a`
    text-align: left;
    font-family: 'Nunito';
    font-size: 18px;    
    color: #000;
`

export const AppCompany = styled.a`
    text-align: left;
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 18px;    
    color: #000;
`

export const Content = styled.div`
    width: calc(100vw - 420px);
    display: flex;
    grid-gap: 30px 30px;
    justify-content: safe center;
    align-items: left;    
    flex-direction: column;
    padding-left: 60px;
`

export const Title = styled.h1`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
`

export const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
`

export const ResultContent = styled.div`
    display: flex;
    flex-direction: column;
`

export const SubTitle = styled.a`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 18px;    
    color: #6D7989;
    margin-bottom: 5px;
`
export const Text = styled.a`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 14px;    
    color: #000;
    margin-bottom: 5px;
`

export const InfoTitle = styled.a`
    font-family: 'Nunito';
    font-size: 12px;    
    color: #6D7989;
    margin-bottom: 5px;
`

export const HalfContent = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px 10px;
    max-height: 100%;
`

export const Photo = styled.img`
    width: 180px;
    height: 180px;
    border-radius: 90px;
`

export const Card = styled.div`
    width: 100%;
    border-radius: 20px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    grid-gap: 15px 0px;
    transition: .5s;
    background: white;
    margin: 10px 0px 10px 0px;
`


export const FullWidthDiv = styled.div`
    width: calc(100% - 320px);
    height: 100%;
    background: ${defines.BackgroundColor};
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

export const Icon = styled.img`
    width: 16px;
    cursor: pointer;
`;

export const SearchIcon = styled.img`
    top: 7.5px;
    left: 7.5px;
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 3;
`

export const RightAlignedText = styled.a`
    font-family: 'Nunito';
    font-size: 22px;    
    color: ${defines.GreenTec};
`

export const AppTitleLeft = styled.a`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 22px;    
`

export const CenteredDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;

export const AppTitleLeftWrapper = styled.div`
    display: flex;
`;

export const InviteInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 2px;
`

export const InviteSender = styled.a`
    font-family: 'Nunito';
    font-size: 16px;    
    font-weight: bold;
`

export const InviteAccessInfo = styled.a`
    font-family: 'Nunito';
    font-size: 16px;    
    color: #6D7989;
`

export const Buttons = styled.div`
    display: flex;
    grid-gap: 10px;
    margin: 10px;
`

export const PlanosTitle = styled.a`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 18px;    
    color: #6D7989;
`


export const FindContent = styled.div`
    min-width: 30px;
    position: relative;
`

export const Find = styled.input`
    width: 30px;
    height: 30px;
    max-width: 200px;
    border-radius: 15px;
    border: 1px solid #1080D6;
    padding-left: 30px;
    font-family: "Nunito";
    transition: .5s;
    background: none;
    z-index: 5;
    :focus{
        transition: .5s;
        width: 300px;
    }
`

export const HeaderButton = styled.div`
    background-color: #1080D6;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
        transition: .3s;
        opacity: 0.8
    }
`
