import axios from 'axios'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/Buttons/Button/Button'
import Input from '../../../components/Inputs/Input/Input'
import Modal from '../../../components/Modals/Modal/Modal'
import * as Body from '../Styles'
import * as Types from '../../../../utils/types/Types'
import * as Functions from '../../../../utils/functions/Functions'
import { useNavigate } from 'react-router-dom'
import { apiURL } from '../../../../utils/defines/Defines'
import SearchIcon from '../../../../assets/icons/Search.svg'
import { State } from '../../../redux/store'


type CreateUnityProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    idEmpresa: string,
    getData: any,
}

export const CreateUnity: React.FC <CreateUnityProps> = ({
    toggle, idEmpresa, getData
}) => {
    const userType = useSelector((state: State) => state.userType.userType)
    const [loading, toggleLoading] = useState(false)
    const [nomeFilial, setNome] = useState('')
    const [cnpj, setCNPJ] = useState('')
    const [endereco, setEndereco] = useState('')
    const [nomeEmpresa, setNomeEmpresa] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const formatCnpj = (value: string) => {
        return value
            .replace(/[^0-9a-zA-Z]/g, '') // Remove all non-alphanumeric characters
            .replace(/^(\w{2})(\w)/, '$1.$2') // Add dot after first 2 characters
            .replace(/^(\w{2})\.(\w{3})(\w)/, '$1.$2.$3') // Add dot after next 3 characters
            .replace(/\.(\w{3})(\w)/, '.$1/$2') // Add slash after next 3 characters
            .replace(/(\w{4})(\w)/, '$1-$2') // Add dash after next 4 characters
            .replace(/(-\w{2})\w+?$/, '$1'); // Prevent more than 14 characters
    }

    const handleCnpjChange = (text: string) => {
        setCNPJ(formatCnpj(text));
    }


    useEffect(()=>{

    }, [dispatch, navigate])

    const tryCreate = () => {
        toggleLoading(true)
        axios.post(apiURL+"/api/FilialCRUD?userTypeRequest=" + userType, {
            nomeFilial, cnpj, endereco, idEmpresa: idEmpresa
        },  {withCredentials: true})
        .then((response)=>{
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Unidade criada com sucesso'})
            getData()
            toggleLoading(false)
            toggle(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal toggleModal={toggle} title="Cadastrar filial" subtitle={nomeEmpresa}>
    <>
     <Body.Scroll style={{width: '400px', 
         gridGap: '10px 0px', marginBottom: '10px'}}>        
         <Input text={nomeFilial} setText={setNome} title="Nome" />
         <div style={{height: '10px'}} />
         <Input text={cnpj} setText={handleCnpjChange} title="CNPJ" />
            <Body.Text style={{ color: 'gray', fontSize: '12px' }}>O CNPJ deverá estar no formato 12.345.678/0002-90</Body.Text>
        <div style={{height: '10px'}} />
         <Input text={endereco} setText={setEndereco} title="Endereço" />
         <div style={{height: '10px'}} />
     </Body.Scroll>
     <Button text="Criar" action={()=>tryCreate()} loading={loading} />
     </>
 </Modal>
}

type ConfirmDeleteProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    id: string,
    nome: string,
    action: any,
}

export const ConfirmDeleteUnity: React.FC <ConfirmDeleteProps> = ({
    toggle, id, action, nome
}) => {
    const userType = useSelector((state: State) => state.userType.userType)
    const [loading, toggleLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const tryDelete = () => {
        toggleLoading(true)
        axios.delete(apiURL+"/api/FilialCRUD?idFilial="+Functions.ReplaceSpecialCharacters(id) + "&userTypeRequest=" + userType,
        {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Filial excluida com sucesso'})
            toggleLoading(false)
            action()
            toggle(false)
        })
        .catch(error => {
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal title="Excluir Filial">
        <div style={{display: 'flex', flexDirection: 'column',
            alignItems: 'center'}}>
            <Body.Text style={{marginBottom: '20px', fontWeight: 400}}>
                Tem certeza que deseja excluir a filial <strong>{nome}</strong>?
                <br/> Essa ação não poderá ser desfeita.
            </Body.Text>
            <Body.Row>
                <Button text="Excluir" width={200} color="red" 
                    action={()=>tryDelete()} loading={loading} />
                <Button text="Cancelar" width={200} action={()=>toggle(false)} 
                    disabled={loading} />
            </Body.Row>
        </div>
    </Modal>
}

