import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import * as Functions from '../../../utils/functions/Functions';
import Input from "../../components/Inputs/Input/Input";
import * as Types from '../../../utils/types/Types';
// import imageCompression from "browser-image-compression";
import Button from "../../components/Buttons/Button/Button";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import { State } from "../../redux/store";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";
import Avator from '../../../assets/images/avatar.png';
import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
import CheckIcon from '../../../assets/icons/check-icon-white.svg';
import CloseIcon from '../../../assets/icons/closeIconMaterial.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import SearchIcon from '../../../assets/icons/Search.svg';
import ButtonWrapper from "../../components/Buttons/ButtonWrapper/ButtonWrapper";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import SelectUnity from "./modals/SelectUnity";
import SeeHistory from "./modals/SeeHistory";
import InviteDefault from "./modals/InviteDefault";
import InviteVisiting from "./modals/InviteVisiting";
import DeleteIcon from '../../../assets/icons/delete-red-material-icon.svg';
import EditIcon from '../../../assets/icons/edit-blue-material-icon.svg';
import DeleteUser from "./modals/DeleteUser";
import EditVisitingUser from "./modals/EditVisitingUser";
import EditUser from "./modals/EditUser";
import EditSubscription from "./modals/EditSubscription";

type AssinaturaProps = {
    idFilial: string;
    idAssinatura: string;
    idPlano: string;
    dataInicial: string;
    dataVencimento: string;
    nomePlano: string;
    quotaUsuarios: number;
    quotaAtuadorSensor: number;
    quotaEnvio: number;
    preco: number;
    usuarios: {
        linkedUsersList: {
            idUsuario: string;
            nomeCompleto: string;
            email: string;
            visitante?: number;
            cargo: number;
            idVisitante: string;
            idAPI: string;
            validade: string;
        }[];
        visitingUsersList	: {
            idUsuario: string;
            nomeCompleto: string;
            email: string;
            visitante?: number;
            cargo: number;
            idVisitante: string;
            idAPI: string;
            validade: string;
        }[];
        availableUsersList: {
            idUsuario: string;
            nomeCompleto: string;
            email: string;
            visitante?: number;
            cargo: number;
            idVisitante: string;
            idAPI: string;
            validade: string;
        }[];
    };
};

type FilialProps = {
    idFilial: string;
    nomeFilial: string;
    cnpjFilial: string;
    statusAssinatura: number;
    idEmpresa: string;
    cnpjEmpresa: string;
    nomeEmpresa: string;
}

const Cargos: { [key: number]: string } = {
    1: "Técnico",
    2: "Supervisor",
    3: "Administrador"
}

const CargosVisitante: { [key: number]: string } = {
    1: "Técnico Visitante",
    2: "Suporte Visitante",
}

const ManageSubscription = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const acessLevel = useSelector((state: State) => state.acessLevel.acessLevel);
    const userType = useSelector((state: State) => state.userType.userType);

    const location = useLocation();
    const { app }: { app: string } = location.state; 

    const [selectedFilial, setSelectedFilial] = useState<FilialProps>();
    const [selectUnity, toggleUnity] = useState(true);
    const [filiais, setFiliais] = useState<FilialProps[]>([]);

    const [empresas, setEmpresas] = useState<Types.EmpresaInfo[]>([]);
    const [assinatura, setAssinatura] = useState<AssinaturaProps | null>(null);

    const [loadingAssinatura, setLoadingAssinatura] = useState(false);
    const [loading, setLoading] = useState(true);
    const [hasLenght, toggleHasLength] = useState(false)
    const inputUserLinked = useRef<any>()
    const inputUserAvailable = useRef<any>()

    const [filteredUsers, setFilteredUsers] = useState<AssinaturaProps['usuarios']['availableUsersList']>([])
    const [filteredVisitors, setFilteredVisitors] = useState<AssinaturaProps['usuarios']['availableUsersList']>([]);

    const [selectedUser, setSelectedUser] = useState<AssinaturaProps['usuarios']['availableUsersList'][0]>();

    const [seeHistory, toggleSeeHistory] = useState(false);
    const [isCreateInviteVisiting, toggleCreateInviteVisiting] = useState(false);
    const [isCreateInviteDefault, toggleCreateInviteDefault] = useState(false);
    const [editVisitingUser, toggleEditVisitingUser] = useState(false);
    const [editUser, toggleEditUser] = useState(false);
    const [deleteUser, toggleDeleteUser] = useState(false);
    const [editSubscription, toggleEditSubscription] = useState(false);

    const getAplicativo = (app: string) => {
        switch (app) {
            case 'DHM':
                return 'DHM4.0'
            case 'REL':
                return 'Relatórios'
            case 'INS':
                return 'IndustrySight'
            default:
                navigate('/permissao')
                return ''
        }
    }

    const filterUserData = (name: string) => {
        if (name.length === 0) {
            setFilteredUsers(assinatura?.usuarios.linkedUsersList || []);
            toggleHasLength(false);
            return;
        }

        const filtered = assinatura?.usuarios.linkedUsersList.filter(user =>
            user.nomeCompleto.toUpperCase().includes(name.toUpperCase())
        );

        setFilteredUsers(filtered || []); 
        toggleHasLength((filtered ?? []).length > 0);
    }

    const filterUserDataVisitantes = (name: string) => {
        if (name.length === 0) {
            setFilteredVisitors(assinatura?.usuarios.visitingUsersList || []);
            toggleHasLength(false);
            return;
        }
    
        const filtered = assinatura?.usuarios.visitingUsersList.filter(user =>
            user.nomeCompleto.toUpperCase().includes(name.toUpperCase())
        );
    
        setFilteredVisitors(filtered || []);
        toggleHasLength((filtered ?? []).length > 0);
    }
    
    const changeCompany = () => {
        setLoadingAssinatura(true);
        toggleUnity(true);
    }

    const setSelectedUnity = (filial: FilialProps) => {
        setAssinatura(null);
        setLoading(true);
        setSelectedFilial(filial);
    
        if (filial.statusAssinatura !== 1) {
            setLoadingAssinatura(false);
            setLoading(false);
            return;
        }
    
        axios.get(`${defines.apiURL}/api/AssinaturaCRUD?subscriptionByBranch=1&api=${app}&idFilial=${Functions.ReplaceSpecialCharacters(filial.idFilial)}&userTypeRequest=${userType}`, { withCredentials: true })
            .then((response) => {
                const assinaturaData = response.data.content[0];
                setAssinatura(assinaturaData);
    
                // Filtrar apenas os visitantes
                const linkados = assinaturaData.usuarios.linkedUsersList
                const visitantes = assinaturaData.usuarios.visitingUsersList
    
                // Atualiza as listas filtradas
                setFilteredUsers(linkados); // Apenas vinculados (não visitantes)
                setFilteredVisitors(visitantes); // Apenas visitantes
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content[0]);
            })
            .finally(() => {
                setLoadingAssinatura(false);
                setLoading(false);
            });
    }
    

    const regerenateAssinatura = () => {    
        if(!selectedFilial) return

        setAssinatura(null);
        setLoading(true);
    
        axios.get(`${defines.apiURL}/api/AssinaturaCRUD?subscriptionByBranch=1&api=${app}&idFilial=${Functions.ReplaceSpecialCharacters(selectedFilial.idFilial)}&userTypeRequest=${userType}`, { withCredentials: true })
            .then((response) => {
                const assinaturaData = response.data.content[0];
                setAssinatura(assinaturaData);
    
                // Filtrar apenas os visitantes
                const linkados = assinaturaData.usuarios.linkedUsersList
                const visitantes = assinaturaData.usuarios.visitingUsersList
    
                // Atualiza as listas filtradas
                setFilteredUsers(linkados); // Apenas vinculados (não visitantes)
                setFilteredVisitors(visitantes); // Apenas visitantes
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content[0]);
            })
            .finally(() => {
                setLoadingAssinatura(false);
                setLoading(false);
            });
    }


    const getFiliais = () => {
        return filiais;
    }

    useEffect(() => {
        setLoading(true);
        setLoadingAssinatura(true);

        let localEmpresas: Types.EmpresaInfo[] = [];

        axios.get(defines.apiURL + "/api/EmpresaCRUD?userTypeRequest=" + userType, { withCredentials: true })
            .then((response) => {
                setEmpresas(response.data.content);
                localEmpresas = response.data.content;
                let allFiliais: FilialProps[] = [];

                const fetchFiliais = localEmpresas.map((empresa) => {
                    return axios
                        .get(`${defines.apiURL}/api/EmpresaCRUD?apiInfoBranches=1&idEmpresa=${Functions.ReplaceSpecialCharacters(empresa.idEmpresa)}&api=${app}&userTypeRequest=${userType}`, { withCredentials: true })
                        .then((response) => {
                            const subscribedFiliais = response.data.content.haveSubscription.map(
                                (filial: FilialProps) => ({
                                    ...filial,
                                    nomeEmpresa: empresa.nomeEmpresa,
                                    cnpjEmpresa: empresa.cnpj,
                                    idEmpresa: empresa.idEmpresa,
                                })
                            );

                            const unsubscribedFiliais = response.data.content.notHaveSubscription.map(
                                (filial: FilialProps) => ({
                                    ...filial,
                                    statusAssinatura: 0,
                                    nomeEmpresa: empresa.nomeEmpresa,
                                    cnpjEmpresa: empresa.cnpj,
                                    idEmpresa: empresa.idEmpresa,
                                })
                            );

                            allFiliais = [...allFiliais, ...subscribedFiliais, ...unsubscribedFiliais];
                        })
                        .catch((error) => {
                            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
                        });
                });

                Promise.all(fetchFiliais).then(() => {
                    setFiliais(allFiliais);
                    setLoading(false);
                });
            })
            .catch((error) => {
                Functions.ResolveErrors(
                    error.response.status,
                    error.response.data.code,
                    error.response.data.message,
                    navigate,
                    dispatch,
                    error.response.data.content
                );
            });
    }, [navigate, dispatch]);

    const getCargo = (cargo: number) => {
        return Cargos[cargo];
    }

    const getCargoVisitante = (cargo: number) => {
        return CargosVisitante[cargo];
    }

    const userDelete = (user: AssinaturaProps['usuarios']['availableUsersList'][0]) => {
        setSelectedUser(user);
        toggleDeleteUser(true);
    }

    const userEditVisiting = (user: AssinaturaProps['usuarios']['availableUsersList'][0]) => {
        setSelectedUser(user);
        toggleEditVisitingUser(true);
    }

    const userEdit = (user: AssinaturaProps['usuarios']['availableUsersList'][0]) => {
        setSelectedUser(user);
        toggleEditUser(true);
    }


    return (
        <Body.Container>
            <Sidebar type={userType} content={defines.ContentSidebar.APPS_AND_PLANS} />
            <Body.Content>

                {loadingAssinatura ? (
                    <></>
                ) : (
                    <>
                    <Body.Row style={{width: "100%", justifyContent: 'space-between', alignItems: 'center'}}>
                        <Breadcrumb aplicativo={getAplicativo(app)} empresa={selectedFilial?.nomeEmpresa || 'Empresa'} filial={selectedFilial?.nomeFilial || 'Filial'} />
                        <Body.Row style={{maxWidth: '90%', minWidth: '50px'}}>
                            <ButtonWithoutBG action={changeCompany} text="Trocar Empresa" />
                        </Body.Row>
                    </Body.Row>

                    <Body.Row>
                        <Body.Card>
                            <Body.ActivePlanTitle>
                                <Body.PlanTitle>Status da Assinatura</Body.PlanTitle>
                            </Body.ActivePlanTitle>

                            {assinatura ? (
                                <>
                                    <Body.IconWrapper hasSubscription={!!assinatura}>
                                        <Body.Icon src={CheckIcon} />
                                    </Body.IconWrapper>
                                    <br />
                                    <Body.Title>{assinatura?.nomePlano}</Body.Title>
                                    <Body.PlanDescription>
                                        Esta filial possui um plano <strong>ativo</strong>
                                    </Body.PlanDescription>
                                    <Body.Expiration>expira em:</Body.Expiration>
                                    <Body.ExpirationDate>{new Date(assinatura?.dataVencimento).toLocaleDateString()}</Body.ExpirationDate>
                                </>
                            ) : (
                                <>
                                    <Body.IconWrapper hasSubscription={!!assinatura}>
                                        <Body.Icon src={CloseIcon} />
                                    </Body.IconWrapper>
                                    <br />
                                    <Body.PlanDescription>
                                        Esta filial <strong>não possui</strong> um plano ativo
                                    </Body.PlanDescription>
                                </>
                            )}
                            <br />
                            <Body.Row>
                                
                                <Button 
                                    text={assinatura ? "Editar Assinatura" : "Criar Assinatura"} 
                                    action={() => {toggleEditSubscription(true)}} 
                                    color={assinatura ? "yellow" : "green"} 
                                    disabled={acessLevel !== defines.CargoTectrol['Comercial'] && acessLevel !== defines.CargoTectrol['Suporte']}
                                />
                            </Body.Row>
                            <Body.Row>
                                <Button text="Visualizar Histórico" action={() => {toggleSeeHistory(true)}} />
                            </Body.Row>
                            <br />
                        </Body.Card>

                        <Body.HalfContent style={{width: '80%'}}>
                        <Body.Row style={{width: "100%", height: '120px', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Body.Title>{assinatura ? "Usuários Vinculados" : "Sem assinatura para vincular usuários."}</Body.Title>
                                {assinatura ? (
                                <Body.Row style={{maxWidth: '90%', minWidth: '50px'}}>
                                    <Body.FindContent>
                                        <Body.Find
                                            style={hasLenght ? {width: '300px'} : {}}
                                            onChange={e => filterUserData(e.target.value)}
                                            ref={inputUserLinked}
                                        />
                                        <Body.HeaderIcon src={SearchIcon} style={{cursor: 'text'}} onClick={() => inputUserLinked.current.focus()} />
                                    </Body.FindContent>
                                    { acessLevel !== defines.CargoTectrol['Comercial'] && acessLevel !== defines.CargoTectrol['Suporte'] ? null :
                                    <Body.HeaderButton onClick={() => {toggleCreateInviteDefault(true)}}>
                                        <Body.HeaderIcon src={AddIcon} style={{position: 'relative', top: 0, left: 0}} />
                                    </Body.HeaderButton>
                                    }
                                </Body.Row>     
                                ) : null}
                            </Body.Row>
                            {assinatura ? (
                            <Body.Scroll style={{maxHeight: 'calc(100% - 80px)'}}>
                                {filteredUsers.map((user, index) => (
                                    <Body.SelectUser onClick={() => {}} key={index.toString()}>
                                        <Body.Text>{user.nomeCompleto}</Body.Text>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <Body.Cargo>{getCargo(user.cargo)}</Body.Cargo>
                                                {
                                                    acessLevel !== defines.CargoTectrol['Comercial'] && acessLevel !== defines.CargoTectrol['Suporte'] ? null : (
                                                        <>
                                                            <ButtonWrapper action={() => {userDelete(user)}}>
                                                                <Body.UserIcons src={DeleteIcon} />
                                                            </ButtonWrapper>
                                                            <ButtonWrapper action={() => {userEdit(user)}}>
                                                                <Body.UserIcons src={EditIcon} />
                                                            </ButtonWrapper>
                                                        </>
                                                    )
                                                }

                                            </div>
                                    </Body.SelectUser>
                                ))}
                            </Body.Scroll>
                            ) : 
                            <Body.Text>Acesse o botão "Criar Assinatura" e acione um plano para poder vincular usuários.</Body.Text>
                            }
                            { app === 'DHM' ? null :
                                <>
                                    <Body.Row style={{ width: "100%", height: '120px', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Body.Title>{assinatura ? "Usuários Visitantes" : ""}</Body.Title>
                                        {assinatura ? (
                                            <Body.Row style={{ maxWidth: '90%', minWidth: '50px' }}>
                                                <Body.FindContent>
                                                    <Body.Find
                                                        style={hasLenght ? { width: '300px' } : {}}
                                                        onChange={e => filterUserDataVisitantes(e.target.value)}
                                                        ref={inputUserAvailable}
                                                    />
                                                    <Body.HeaderIcon src={SearchIcon} style={{ cursor: 'text' }} onClick={() => inputUserAvailable.current.focus()} />
                                                </Body.FindContent>
                                                {acessLevel !== defines.CargoTectrol['Comercial'] && acessLevel !== defines.CargoTectrol['Suporte'] ? null : (
                                                    <Body.HeaderButton onClick={() => { toggleCreateInviteVisiting(true) }}>
                                                        <Body.HeaderIcon src={AddIcon} style={{ position: 'relative', top: 0, left: 0 }} />
                                                    </Body.HeaderButton>
                                                )}
                                            </Body.Row>
                                        ) : null}
                                    </Body.Row>
                                    {assinatura ? (
                                        <Body.Scroll style={{ maxHeight: 'calc(100% - 80px)' }}>
                                            {filteredVisitors.map((user, index) => (
                                                <Body.SelectUser onClick={() => { }} key={index.toString()}>
                                                    <Body.Text>{user.nomeCompleto}</Body.Text>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <Body.Cargo>{getCargoVisitante(user.cargo)}</Body.Cargo>
                                                        {
                                                            acessLevel !== defines.CargoTectrol['Comercial'] && acessLevel !== defines.CargoTectrol['Suporte'] ? null : (
                                                                <>
                                                                    <ButtonWrapper action={() => {userDelete(user)}}>
                                                                        <Body.UserIcons src={DeleteIcon} />
                                                                    </ButtonWrapper>
                                                                    <ButtonWrapper action={() => {userEditVisiting(user)}}>
                                                                        <Body.UserIcons src={EditIcon} />
                                                                    </ButtonWrapper>
                                                                </>
                                                        )}
                                                    </div>
                                                </Body.SelectUser>
                                            ))}
                                        </Body.Scroll>
                                    ) : null}
                                </>
                            }

                        </Body.HalfContent>
                    </Body.Row>
                    </>
                )}

            </Body.Content>

            {/* Selecionar Filial quando carrega a página */}
            {selectUnity && <SelectUnity setFilial={setSelectedUnity} filiais={getFiliais()} toggle={toggleUnity} />}

            {/* Editar Assinatura */}
            {editSubscription && selectedFilial &&
            <EditSubscription
                toggle={toggleEditSubscription}
                idFilial={selectedFilial.idFilial}
                regenerateAssinatura={regerenateAssinatura}
                app={app}
            />}

            {/* Histórico */}
            {seeHistory && selectedFilial && <SeeHistory filial={selectedFilial} toggle={toggleSeeHistory} app={app} />}

            {/* Convidar Usuário */}
            {selectedFilial && isCreateInviteVisiting && <InviteVisiting toggle={toggleCreateInviteVisiting} filial={selectedFilial} app={app}/>}
            {selectedFilial && isCreateInviteDefault && <InviteDefault toggle={toggleCreateInviteDefault} filial={selectedFilial} regenerate={regerenateAssinatura} app={app}/>}

            {/* Editar e Deletar Usuário */}
            {editVisitingUser && selectedUser && selectedFilial && <EditVisitingUser toggle={toggleEditVisitingUser} usuario={selectedUser} filial={selectedFilial} regenerate={regerenateAssinatura} app={app}/>}
            {editUser && selectedUser && selectedFilial && <EditUser toggle={toggleEditUser} usuario={selectedUser} filial={selectedFilial} regenerate={regerenateAssinatura} app={app}/>}
            {deleteUser && selectedUser && selectedFilial && <DeleteUser toggle={toggleDeleteUser} usuario={selectedUser} filial={selectedFilial} regenerate={regerenateAssinatura} app={app}/>}

            {loading && <Loading />}
        </Body.Container>
    );
}

export default ManageSubscription;
