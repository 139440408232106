// src/components/Breadcrumb/styles.js
import styled, { keyframes } from "styled-components";
import { Link } from 'react-router-dom';
import * as defines from '../../../utils/defines/Defines'

export const Breadcrumb = styled.nav`
    font-family: "Nunito";
    transition: .5s;
    background-color: #f8f9fa;
    transition: .5s;
`

export const BreadcrumbList = styled.ol`
    display: flex;
    list-style: none;
    align-items: center;
    transition: .5s;
`

export const BreadcrumbItem = styled.li`
    display: flex;
    align-items: center;
    font-size: 16px;

    &::after {
        font-weight: bold;
        content: '>';
        margin: 10px;
        color: #838383;
    }

    &:last-child::after {
        content: '';
    }

    &.active {
        font-weight: bold;
        color: black;
    }
`

export const BreadcrumbLink = styled(Link)`
    text-decoration: none;
    font-family: "Nunito";
    color: #838383;
    transition: .5s;
    &:hover {
        transition: .5s;
        text-decoration: underline;
    }
`
