import * as Actions from './actionsType'

const ReqUnity = (state: boolean = false, actions: Actions.UnityProps) => {
    switch(actions.type){
        case(Actions.REQ_UNITY):
            state = true
            return state
        case(Actions.REMOVE_UNITY):
            state = false
            return state
    }
    return state
}

export default ReqUnity