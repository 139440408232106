// src/components/Breadcrumb/Breadcrumb.tsx
import React from 'react';
import * as Body from './Styles';
import { Link, useLocation } from 'react-router-dom';

type BreadcrumbProps = {
    aplicativo?: string,
    empresa?: string,
    filial?: string,
    disableSublinks?: boolean
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
    aplicativo, empresa, filial, disableSublinks = false
}) => {
    const location = useLocation();

    // Divide o pathname em partes
    const pathnames = location.pathname.split('/').filter(x => x);

    return (
        <Body.Breadcrumb aria-label="breadcrumb">
            <Body.BreadcrumbList>
                {
                    pathnames.map((value, index) => {
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                        const isLast = index === pathnames.length - 1;
                        const isClickable = !disableSublinks || index === 0; // Apenas o primeiro item é clicável se disableSublinks for true
                        
                        // Mapear valores para títulos amigáveis
                        const breadcrumbNameMap: { [key: string]: string } = {
                            'menu': 'Menu',
                            'recuperar': 'Recuperar',
                            'ativar-conta': 'Ativar Conta',
                            'autenticacao': 'Autenticação',
                            'privacidade': 'Privacidade',
                            'privacidade-empresa': 'Privacidade Empresa',
                            'cadastro': 'Cadastro',

                            'minha-conta': 'Minha Conta',

                            'apps-conectados-visitante': 'Apps Conectados',
                            'convites-visitante': 'Convites',

                            'aplicativos': 'Apps e Planos',
                            'apps-conectados': 'Apps Conectados',
                            'planos': 'Gerenciar Planos',
                            'empresas': 'Gerenciar Empresas',
                            'filiais': 'Gerenciar Filiais',
                            'assinaturas': 'Gerenciar Assinaturas',
                            'usuarios': 'Gerenciar Usuários',
                            'tectrol': 'Gerenciar Usuários Tectrol',

                            'gerenciar-usuarios-tectrol': 'Gerenciar Usuários',
                            'gerenciar-empresas': 'Gerenciar Empresas',

                            'gerenciar-planos-empresas': 'Gerenciar Empresas',
                            'gerenciar-planos-filiais': 'Gerenciar Filiais',
                            
                            'empresarial': 'Gerenciar Usuários',
                            'permissoes': 'Gerenciar Permissões',
                            'gerenciar-dhm': 'Gerenciar DHM',
                            'gerenciar-insight': 'Gerenciar Insight',
                            'gerenciar-permissoes': 'Gerenciar Permissões',
                            
                        };

                        const breadcrumbName = breadcrumbNameMap[value] || value;

                        return (
                            <Body.BreadcrumbItem key={to} className={isLast ? 'active' : ''}>
                                {isLast || !isClickable ? (
                                    breadcrumbName
                                ) : (
                                    <Body.BreadcrumbLink to={to}>
                                        {breadcrumbName}
                                    </Body.BreadcrumbLink>
                                )}
                            </Body.BreadcrumbItem>
                        );
                    })
                }
                {aplicativo && empresa ? (
                    <Body.BreadcrumbItem className={'active'}>
                        {aplicativo} na {empresa}
                    </Body.BreadcrumbItem>
                ) : (
                    <>
                        {aplicativo ? (
                            <Body.BreadcrumbItem className={'active'}>
                                {aplicativo}
                            </Body.BreadcrumbItem>
                        ) : null}
                        {empresa ? (
                            <Body.BreadcrumbItem className={'active'}>
                                {empresa}
                            </Body.BreadcrumbItem>
                        ) : null}
                    </>
                )}

                {filial ? (
                    <Body.BreadcrumbItem className={'active'}>
                        {filial}
                    </Body.BreadcrumbItem>
                ) : null}
            </Body.BreadcrumbList>
        </Body.Breadcrumb>
    );
};

export default Breadcrumb;
