import React from "react";
import * as Body from './Styles';
import Spinner from "../../../../assets/icons/spinner-blue.svg";

type ButtonProps = {
    action: any,
    size?: number,
    active?: boolean,
    loading?: boolean,
    color?: string,
    hoverColor?: string,
    icon: string
}

const IconButton: React.FC<ButtonProps> = ({
    action, size, active, loading, color, hoverColor, icon
}) => {
    return loading ? (
        <Body.Icon src={Spinner} />
    ) : (
        <Body.Button onClick={action} size={size} active={active} color={color} hoverColor={hoverColor}>
            <Body.Icon src={icon} />
        </Body.Button>
    );
}

export default IconButton;