import React, { useEffect, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import SearchIcon from '../../../assets/icons/Search.svg'
import AddIcon from '../../../assets/icons/add-icon.svg'
import * as Functions from  '../../../utils/functions/Functions'
import * as defines from  '../../../utils/defines/Defines'
import * as Types from '../../../utils/types/Types'
import Input from "../../components/Inputs/Input/Input";
import Button from "../../components/Buttons/Button/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";
import { State } from "../../redux/store";
import Avatar from '../../../assets/images/avatar.png'
import DHMIcon from '../../../assets/icons/dhm-icon.png'
import INSIcon from '../../../assets/icons/industry-icon.png'
import RELIcon from '../../../assets/icons/chart-icon.png'
import IconButton from "../../components/Buttons/IconButton/IconButton";
import CheckIcon from '../../../assets/icons/checkIconMaterial.svg'
import CloseIcon from '../../../assets/icons/closeIconMaterial.svg'

type InviteProps = {
    idConvite: string;
    nomeUsuarioAnfitriao: string;
    nomeFilial: string;
    nomeEmpresa: string;
    tipoConvite: number;
    validade: string;
    validadeUsuario: string;
    api: string;
};

const VisitingInvites: React.FC = () => {
    const userType = useSelector((state: State) => state.userType.userType);
    const [loading, toggleLoading] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [invites, setInvites] = useState<InviteProps[]>([]);
    const [timeRemaining, setTimeRemaining] = useState<{ [key: string]: string }>({});

    const calculateTimeRemaining = (isoTime: string) => {
        const targetTime = new Date(isoTime);
        const now = new Date();
        const diff = targetTime.getTime() - now.getTime();

        if (diff <= 0) return "00:00:00";

        const remainingHours = Math.floor(diff / (1000 * 60 * 60));
        const remainingMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const remainingSeconds = Math.floor((diff % (1000 * 60)) / 1000);

        return `${remainingHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const refuseInvite = (id: string) => {
        toggleLoading(true);
        axios.post(defines.apiURL+"/api/ConviteUsuarioVisitante?userTypeRequest=" + userType, {idConvite: id, aceitar: 0}, {withCredentials: true})
            .then((response) => {
                setInvites(response.data.content);
                dispatch({ type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Convite recusado com sucesso!' });
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
            });
        toggleLoading(false);
    };

    const acceptInvite = (id: string) => {
        toggleLoading(true);
        axios.post(defines.apiURL+"/api/ConviteUsuarioVisitante?userTypeRequest=" + userType, {idConvite: id, aceitar: 1}, {withCredentials: true})
            .then((response) => {
                setInvites(response.data.content);
                dispatch({ type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Convite aceito com sucesso!' });
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
            });
        toggleLoading(false);
    };

    const getData = (data: string) => {
        const date = new Date(data);
        const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        return formattedDate;
    };

    const getApi = (api: string) => {
        if (api === 'DHM') return { name: 'DHM4.0', icon: DHMIcon };
        if (api === 'INS') return { name: 'IndustrySight', icon: INSIcon };
        if (api === 'REL') return { name: 'Relatórios', icon: RELIcon };
        return { name: '', icon: '' };
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const updatedTimeRemaining = invites.reduce((acc, invite) => {
                acc[invite.idConvite] = calculateTimeRemaining(invite.validade);
                return acc;
            }, {} as { [key: string]: string });

            setTimeRemaining(updatedTimeRemaining);
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [invites]);

    
    useEffect(() => {
        toggleLoading(true);
        axios.get(defines.apiURL+"/api/ConviteUsuarioVisitante?userTypeRequest=" + userType, { withCredentials: true })
            .then((response) => {
                setInvites(response.data.content);
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
            });
        toggleLoading(false);
    }, [dispatch, navigate]);

    return (
        <Body.Container>
            <Sidebar type={userType} content={defines.ContentSidebar.INVITES} />
    
            <Body.FullWidthDiv>
                <Body.SubTitle>Aqui está o histórico de convites para aplicações pendentes de aceite.</Body.SubTitle>
                <Body.SubTitle>Caso não veja o convite que precise aqui, solicite um novo.</Body.SubTitle>
    
                <br />
    
                {invites ? (
                    invites.length === 0 ? (
                        <Body.Text>Nenhum convite disponível</Body.Text>
                    ) : (
                        invites.map((invite) => {
                            const { name, icon } = getApi(invite.api);
                            return (
                                <Body.Card key={invite.idConvite}>
                                    <Body.Row>
                                        <Body.Icon src={icon} alt={`${name} Icon`} />
                                        <Body.CenteredDiv>
                                            <Body.InviteInfo>
                                                <Body.AppTitleLeftWrapper>
                                                    <Body.AppTitleLeft>{name}</Body.AppTitleLeft>
                                                    <Body.AppTitleLeft style={{ fontWeight: 'normal', color: 'grey' }}>&nbsp;na empresa&nbsp;</Body.AppTitleLeft>
                                                    <Body.AppTitleLeft>{invite.nomeEmpresa}</Body.AppTitleLeft>
                                                </Body.AppTitleLeftWrapper>
                                                <Body.InviteSender>Enviado por {invite.nomeUsuarioAnfitriao}</Body.InviteSender>
                                                <Body.InviteAccessInfo>Acesso ao aplicativo até {getData(invite.validadeUsuario)}</Body.InviteAccessInfo>
                                                <Body.InviteAccessInfo>O convite expira em: <strong><span style={{ color: defines.Red,  }}>{timeRemaining[invite.idConvite]}</span></strong></Body.InviteAccessInfo>
                                            </Body.InviteInfo>
                                            <Body.Buttons>
                                                <IconButton action={() => { refuseInvite(invite.idConvite); }} icon={CloseIcon} color={defines.Red} />
                                                <IconButton action={() => { acceptInvite(invite.idConvite); }} icon={CheckIcon} color={defines.GreenTec} />
                                            </Body.Buttons>
                                        </Body.CenteredDiv>
                                    </Body.Row>
                                </Body.Card>
                            );
                        })
                    )
                ) : null}
            </Body.FullWidthDiv>
            {loading ? <Loading /> : null}
        </Body.Container>
    );
};

export default VisitingInvites;
