import styled, { keyframes } from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const Container = styled.div`
    background: ${defines.BackgroundColor};
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;
    overflow: scroll;
`

export const Title = styled.h1`
    font-family: "Comfortaa";
    font-weight: bold;
    color: #000;
    font-size: 22px;
`

export const SubTitle = styled.h3`
    font-family: "Comfortaa";
    font-weight: normal;
    color: #000;
    font-size: 18px;
`

export const Row = styled.div`
    display: flex;
    grid-gap: 0px 10px;
    align-items: center;
    flex-wrap: wrap;
`
export const Circle = styled.div`
    width: 7px;
    height: 7px;
    background: #000;
    border-radius: 10px;
`

export const TitleCompany = styled.h2<{color: string}>`
    font-family: "Signa";
    font-weight: black;
    font-style: italic;
    font-size: 18px;
    color: ${props => props.color};
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 30px 0px;
    padding-bottom: 80px;
    padding-top: 20px;
    align-items: center;
    min-height: 385px;
`

export const TitleContent = styled.div`
    min-height: 200px;
    height: calc(100vh - 385px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Text = styled.p`
    font-family: "Comfortaa";
    font-size: 12px;
    color: #000;
`
const TextAnimate = keyframes`
    from{
        margin-top: -10px;
        opacity: 0;
    }
    to{
        margin-top: 0px;
        opacity: 1;
    }
`

export const TextError = styled.p`
    font-family: "Comfortaa";
    font-size: 10px;
    color: ${defines.Red};
    animation: .35s ${TextAnimate} ease-in;
`

export const CompanyLogo = styled.img`
    height: 20px;
`

export const Card = styled.div`
    margin-top: 40px;
    font-family: "Comfortaa";
    font-weight: normal;
    width: calc(100% - 30px);
    height: calc(100% - 70px);
    margin: 15px;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Added to centralize content */
    box-sizing: border-box;
    grid-gap: 40px 20px;
    transition: .5s;
    -webkit-box-shadow: 10px 10px 30px 1px rgba(211,211,212,0.4),
        -10px -10px 30px 1px rgba(255,255,255,0.8); 
    box-shadow: -10px -10px 30px 1px rgba(255,255,255,0.8),
        10px 10px 30px 1px rgba(211,211,212,0.4);
`

const floatAnimation1 = keyframes`
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-4px);
    }
    100% {
        transform: translatey(0px);
    }
`;

const floatAnimation2 = keyframes`
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-8px);
    }
    100% {
        transform: translatey(0px);
    }
`;

const floatAnimation3 = keyframes`
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-2px);
    }
    100% {
        transform: translatey(0px);
    }
`;

// Estilização para aplicar animações de flutuação com variações
export const FloatIcon1 = styled.img`
    animation: ${floatAnimation1} 3s ease-in-out infinite;
`;

export const FloatIcon2 = styled.img`
    animation: ${floatAnimation2} 3.2s ease-in-out infinite;
`;

export const FloatIcon3 = styled.img`
    animation: ${floatAnimation3} 2.8s ease-in-out infinite;
`;