import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Input from "../../../components/Inputs/Input/Input";
import Modal from "../../../components/Modals/Modal/Modal";
import * as Body from '../Styles';
import Button from "../../../components/Buttons/Button/Button";
import ButtonWrapper from "../../../components/Buttons/ButtonWrapper/ButtonWrapper";
import AdministradorIcon from '../../../../assets/icons/adm-icon.svg';
import EngenheiroIcon from '../../../../assets/icons/usuario-icon.svg';
import * as Functions from  '../../../../utils/functions/Functions'
import * as defines from  '../../../../utils/defines/Defines'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { State } from "../../../redux/store";

type SelectUnitProps = {
    toggle: Dispatch<SetStateAction<boolean>>
}

const TipoUsuario = {
    Comercial: 1,
    Engenharia: 2,
    Suporte: 3,
    Padrao: 4
}

const SendInvite: React.FC<SelectUnitProps> = ({
    toggle
}) => {

    const userType = useSelector((state: State) => state.userType.userType)
    const [loading, toggleLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [errorEmail, toggleErrorEmail] = useState(false);
    const [selectedRole, setSelectedRole] = useState(0);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleSelectRole = (role: number) => {
        setSelectedRole(role); // Atualiza o tipo de usuário selecionado
    }

    const setEmailAux = (email: string) => {
        setEmail(email);
        let correctEmail = Functions.verifyEmail(email)
        if(email == '') correctEmail = true
        toggleErrorEmail(!correctEmail)
           
    }

    const sendInvite = () => {

        let correctEmail = Functions.verifyEmail(email)
        if(email == '') correctEmail = true
        toggleErrorEmail(!correctEmail)
        if(!correctEmail) return

        toggleLoading(true)
        axios.post(defines.apiURL+"/api/TectrolCRUD?userTypeRequest=" + userType, { email: email, tipoTectrol: selectedRole}, {withCredentials: true})
            .then((response) => {
                dispatch({type: 'ADD_NOTIFICATION', status: 1, text: 'Convite enviado com sucesso.', title: ''})
                toggle(false)
                toggleLoading(false)
            })
            .catch((error) => {
                toggleLoading(false)
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }

    return (
        <Modal title="Convidar Usuário Administrador" toggleModal={toggle} loading={loading}>
            <div style={{ width: '600px', display: 'flex', flexDirection: 'column', gridGap: '15px 0px' }}>
                <Body.Row>
                    <Input placeholder="Email" title="Email" text={email} setText={setEmailAux} />
                </Body.Row>
                {errorEmail?<Body.TextError>
                Email inválido, verifique e tente novamente
                </Body.TextError>:null}

                <ButtonWrapper
                    style={{ width: '100%' }}
                    action={() => handleSelectRole(TipoUsuario.Comercial)} // Define o tipo de usuário ao clicar
                >
                    <Body.ModalCard
                        style={{
                            backgroundColor: selectedRole === TipoUsuario.Comercial ? '#ebebeb' : 'white', // Muda a cor se for o selecionado
                        }}
                    >
                        <Body.Row>
                            <Body.ModalCardIcon src={AdministradorIcon} />
                            <Body.Column>
                                <Body.ButtonTitle>Comercial</Body.ButtonTitle>
                                <Body.Text>Gerencia usuários e planos das aplicações.</Body.Text>
                            </Body.Column>
                        </Body.Row>
                    </Body.ModalCard>
                </ButtonWrapper>

                <ButtonWrapper
                    style={{ width: '100%' }}
                    action={() => handleSelectRole(TipoUsuario.Engenharia)}
                >
                    <Body.ModalCard
                        style={{
                            backgroundColor: selectedRole === TipoUsuario.Engenharia ? '#ebebeb' : 'white',
                        }}
                    >
                        <Body.Row>
                            <Body.ModalCardIcon src={EngenheiroIcon} />
                            <Body.Column>
                                <Body.ButtonTitle>Engenharia</Body.ButtonTitle>
                                <Body.Text>Gerencia usuários das aplicações.</Body.Text>
                            </Body.Column>
                        </Body.Row>
                    </Body.ModalCard>
                </ButtonWrapper>

                <ButtonWrapper
                    style={{ width: '100%' }}
                    action={() => handleSelectRole(TipoUsuario.Suporte)}
                >
                    <Body.ModalCard
                        style={{
                            backgroundColor: selectedRole === TipoUsuario.Suporte ? '#ebebeb' : 'white',
                        }}
                    >
                        <Body.Row>
                            <Body.ModalCardIcon src={AdministradorIcon} />
                            <Body.Column>
                                <Body.ButtonTitle>Suporte</Body.ButtonTitle>
                                <Body.Text>Gerencia usuários do Conecta e aplicações.</Body.Text>
                            </Body.Column>
                        </Body.Row>
                    </Body.ModalCard>
                </ButtonWrapper>

                <ButtonWrapper
                    style={{ width: '100%' }}
                    action={() => handleSelectRole(TipoUsuario.Padrao)}
                >
                    <Body.ModalCard
                        style={{
                            backgroundColor: selectedRole === TipoUsuario.Padrao ? '#ebebeb' : 'white',
                        }}
                    >
                        <Body.Row>
                            <Body.ModalCardIcon src={EngenheiroIcon} />
                            <Body.Column>
                                <Body.ButtonTitle>Padrão</Body.ButtonTitle>
                                <Body.Text>Apenas possui acesso às aplicações.</Body.Text>
                            </Body.Column>
                        </Body.Row>
                    </Body.ModalCard>
                </ButtonWrapper>

                <Body.Row>
                    <Body.Text>
                        Se a pessoa que deseja convidar ainda não faz parte do Conecta,
                        serão enviados dois convites: para cadastro de usuário e
                        outro para participar da filial!
                    </Body.Text>
                </Body.Row>

                <Button text="Convidar" action={() => {sendInvite()}} loading={loading}/>
            </div>
        </Modal>
    );
}

export default SendInvite;
