import React, { useEffect, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import DHMIcon from '../../../assets/icons/dhm-icon.svg'
import ConectaIcon from '../../../assets/icons/conecta-icon.svg'
import Button from "../../components/Buttons/Button/Button";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { State } from "../../redux/store";
import IndustryIcon from '../../../assets/icons/industry-icon.png'
import ChartIcon from '../../../assets/icons/chart-icon.png'

type AppsProps = {
    api: string,
    cargo: number
}

const ConnectedApps = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const acessLevel = useSelector((state: any) => state.acessLevel.acessLevel)
    const userType = useSelector((state: State) => state.userType.userType);
    const selectedUnity = useSelector((state: State) => state.selectedUnity.selectedUnity);

    const [loading, toggleLoading] = useState(true)
    const [apps, setApps] = useState<AppsProps[]>([])

    useEffect(()=>{
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/UsuarioCRUD?registeredApps=1&idFilial=" + Functions.ReplaceSpecialCharacters(selectedUnity) + "&userTypeRequest=" + userType, {withCredentials: true})
            .then(response => {
                setApps(response.data.content)
                toggleLoading(false)
            })
            .catch(error => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, navigate])


    const openApp = (link: string) => {
        window.open(link, "_blank");
    };	

    return <Body.Container>
        <Sidebar type={defines.TypeSidebar.DEFAULT_USER} content={defines.ContentSidebar.CONNECTED_APPS} />

        <Body.Content>
            <Body.Text style={{marginBottom: '20px'}}>
                Aqui estão as aplicações que estão vinculadas à sua conta. <br/>
                Caso não veja a aplicação que precise acessar vinculada aqui, entre em contato.
            </Body.Text>

            <Body.Row>
                {apps.some(app => app.api === "DHM")?
                <>
                <Body.Card>
                    <div>
                        <Body.Icon src={DHMIcon} />
                        <Body.Title>
                            DHM4.0
                        </Body.Title>
                        <Body.SubTitle>
                            Monitore e personalize o sistema DHM4.0 operando em sua usina
                        </Body.SubTitle>
                    </div>
                    <div>
                        <Button text="Abrir Aplicativo" action={()=>openApp("https://www.dhm.dinamoautomacao.com.br")}/>
                        <br />
                        { apps.find(app => app.api === "DHM")?.cargo === defines.CargoApi['Administrador'] ? <Button text="Gerenciar Permissões" action={()=>navigate('/apps-conectados/permissoes', { state: { app: 'DHM' } })} color="yellow" /> : null}
                    </div>
                </Body.Card>
                </>:null}
                {apps.some(app => app.api === "REL")?
                <>
                <Body.Card>
                    <div>
                        <Body.Icon src={ChartIcon} />
                        <Body.Title>
                            Relatórios
                        </Body.Title>
                        <Body.SubTitle>
                            Veja os relatórios das Ordens de Serviços realizadas pelo grupo Tectrol e Dínamo
                        </Body.SubTitle>
                    </div>
                    <div>
                        <Button text="Abrir Aplicativo" action={()=>openApp("https://www.dhm.dinamoautomacao.com.br")}/>
                        <br />
                        { apps.find(app => app.api === "REL")?.cargo === defines.CargoApi['Administrador'] ? <Button text="Gerenciar Permissões" action={()=>navigate('/apps-conectados/permissoes', { state: { app: 'REL' } })} color="yellow" /> : null}
                    </div>
                </Body.Card>
                </>:null}
                {apps.some(app => app.api === "INS")?
                <>
                <Body.Card>
                    <div>
                        <Body.Icon src={IndustryIcon} />
                        <Body.Title>
                            Industry Sight
                        </Body.Title>
                        <Body.SubTitle>
                            Veja a operação da sua indústria em tempo real
                        </Body.SubTitle>
                    </div>
                    <div>
                        <Button text="Abrir Aplicativo" action={()=>openApp("#")}/>
                        <br />
                        { apps.find(app => app.api === "INS")?.cargo === defines.CargoApi['Administrador'] ? <Button text="Gerenciar Permissões" action={()=>navigate('/apps-conectados/permissoes', { state: { app: 'INS' } })} color="yellow" /> : null}
                    </div>
                </Body.Card>
                </>:null}
            </Body.Row>
            
        </Body.Content>

      {loading?<Loading />:null}
    </Body.Container>
}

export default ConnectedApps