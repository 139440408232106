import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Input from "../../../components/Inputs/Input/Input";
import Modal from "../../../components/Modals/Modal/Modal";
import * as Body from '../Styles';
import Button from "../../../components/Buttons/Button/Button";
import ButtonWrapper from "../../../components/Buttons/ButtonWrapper/ButtonWrapper";
import ComercialIcon from '../../../../assets/icons/supervisor-icon.svg';
import EngenheiroIcon from '../../../../assets/icons/tecnico-icon.svg';
import SuporteIcon from '../../../../assets/icons/supervisor-icon.svg';
import PadraoIcon from '../../../../assets/icons/tecnico-icon.svg';
import * as Functions from  '../../../../utils/functions/Functions'
import * as defines from  '../../../../utils/defines/Defines'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from '../../../../assets/icons/Search.svg'
import DatetimeInput from "../../../components/DatetimeInput/DatetimeInput";
import { State } from "../../../redux/store";
import CheckIcon from '../../../../assets/icons/check-icon-white.svg'

export const CargoEmpresarial = {
    "Padrão": 1,
    "Administrador": 2,
}


type UsuarioProps = {
    idEmpresarial: string,
    idUsuario: string,
    fotoUsuario: string | null,
    ddd: string,
    celular: string,
    nomeCompleto: string,
    email: string,
    cargo: number,
    encarregadoDados: number
}


type SelectUnitProps = {
    toggle: Dispatch<SetStateAction<boolean>>
    usuario: UsuarioProps
    regenerate: () => void
    idFilial: string
}

const UpdateCargo: React.FC <SelectUnitProps> = ({
    toggle, usuario, regenerate, idFilial
}) => {

    const userType = useSelector((state: State) => state.userType.userType)

    const [selected, setSelected] = useState(usuario.cargo)
    const [loading, toggleLoading] = useState(false)
    const [isEncarregadoDados, toggleEncarregadoDados] = useState(usuario.encarregadoDados === 1)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const changeUser = () => {
        toggleLoading(true);
        axios.put(defines.apiURL + '/api/EmpresarialCRUD' + "?userTypeRequest=" + userType + "&idFilial=" + Functions.ReplaceSpecialCharacters(idFilial), {
            idEmpresarial: usuario.idEmpresarial,
            encarregadoDados: isEncarregadoDados,
            nivelAcesso: selected,
        }, { withCredentials: true })
            .then(response => {
                toggleLoading(false);
                toggle(false);
                dispatch({ type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Tipo alterado com sucesso!' });
                regenerate();
            })
            .catch(error => {
                toggleLoading(false);
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            });
    }

    return <Modal title="Alterar Cargo">
        <>
            <Body.Row>
                <Body.Text>Selecione o novo cargo e permissões do usuário <strong>{usuario.nomeCompleto}</strong>.</Body.Text>
            </Body.Row>
            <Body.Row style={{marginTop: '15px', justifyContent: 'space-around'}}>

                <Body.SelectCard active={selected === CargoEmpresarial['Administrador']} 
                    onClick={()=>setSelected(CargoEmpresarial['Administrador'])}>
                        <Body.IconChangePermission 
                            src={ComercialIcon}
                            />
                        <Body.Text>Administrador</Body.Text>
                </Body.SelectCard>
                <Body.SelectCard active={selected === CargoEmpresarial['Padrão']}  
                    onClick={()=>setSelected(CargoEmpresarial['Padrão'])}>
                        <Body.IconChangePermission
                            src={EngenheiroIcon}
                            />
                        <Body.Text>Padrão</Body.Text>
                    </Body.SelectCard>
            </Body.Row>
            <br/>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={()=>toggleEncarregadoDados(!isEncarregadoDados)}>
            <Body.ToggleBtn active={isEncarregadoDados}>
                {isEncarregadoDados?<Body.IconCheckbox src={CheckIcon} />:null}
            </Body.ToggleBtn>
            <Body.Text style={{width: 'calc(100% - 40px)'}}>
                Encarregado de Dados
            </Body.Text>
            </div>
            <Body.Row style={{gridGap: '0px 10px', justifyContent: 'center', marginTop: '20px'}}>
                <Button text="Alterar" color="green" action={()=>{changeUser()}}
                    width={120} loading={loading} />
                <Button text="Cancelar" color="red" action={()=>toggle(false)} disabled={loading}
                    width={120}  />
            </Body.Row>
        </>
    </Modal>
}

export default UpdateCargo;