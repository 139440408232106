// Table.tsx

import React, { useState } from "react";
import * as defines from '../../../utils/defines/Defines';
import * as Body from './SmallTableStyles';
import EditIcon from "../../../assets/icons/edit-icon-blue.svg";
import TrashIcon from "../../../assets/icons/delete-icon-red.svg";
import IconButton from "../../components/Buttons/IconButton/IconButton";
import Button from "../../components/Buttons/Button/Button";
import ButtonWithoutBG from "../Buttons/ButtonWithoutBG/Button";

interface TableProps {
    content: PlanoProps;
    contentMap: { [key: string]: string };
    dynamicDescriptions: string[];
}

interface BasePlanoProps {
    idPlanoInSight?: string;
    idPlanosDHM?: string;
    idPlanoRelatorios?: string;
    nomePlano: string;
    preco: number;
    createdAt: string;
}

type PlanoProps = BasePlanoProps & {
    [key: string]: any;
    [key: number]: any;
};

const SmallTable: React.FC<TableProps> = ({
    content,
    contentMap,
    dynamicDescriptions,
}) => {

    const formatDate = (dataIso: string) => {
        const date = new Date(dataIso);
        return date.toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
    };

    const filterPlanName = (name: string) => {
        if (!isNaN(Number(name))) return name;
        return name
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const filterPlanPrice = (price: number) => price.toFixed(2);

    return (

        <Body.TableWrapper>
        <Body.TableNoSpacing>
            <thead>
                <tr>
                <Body.CombinedTdTitle1>{filterPlanName(content.nomePlano)}</Body.CombinedTdTitle1>
                <Body.CombinedTdTitle2>
                    {content.preco === 0 ? 'Cortesia' : `R$ ${filterPlanPrice(content.preco)}`}
                </Body.CombinedTdTitle2>
                </tr>
                </thead>
                { content.createdAt &&
                    <>
                    <br/>
                    <thead>
                    <tr key="createdAt">
                    <Body.TdTitle style={{ color: 'gray' }}>Data de Criação</Body.TdTitle>
                    <Body.Td style={{ color: 'gray' }}>{formatDate(content.createdAt)}</Body.Td>
                    </tr>
                    </thead>
                    </>
                }
        </Body.TableNoSpacing>

        <Body.Table>
        <tbody>
            <tr>
            <Body.TdTitleBold>Quotas</Body.TdTitleBold>
            <Body.TdBold>Qtd.</Body.TdBold>
            </tr>
            {dynamicDescriptions.map((description, index) => (
            <tr key={index}>
                <Body.TdTitle>{contentMap[description]}</Body.TdTitle>
                <Body.Td>{(content as any)[description]}</Body.Td>
            </tr>
            ))}
        </tbody>
        </Body.Table>

    </Body.TableWrapper>

    );
};

export default SmallTable;
