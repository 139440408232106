import styled, { keyframes } from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const Container = styled.div`
    background: ${defines.BackgroundColor};
    width: 100vw;
    height: 100vh;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: flex-end;
`

export const Content = styled.div`
    width: calc(100vw - 420px);
    height: 100%;
    display: flex;
    grid-gap: 10px 10px;
    justify-content: space-around;
    align-items: center;

`

export const Title = styled.h1`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
`

export const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
`

export const ResultContent = styled.div`
    display: flex;
    flex-direction: column;
`

export const SubTitle = styled.a`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 18px;    
    color: #6D7989;
    margin-bottom: 5px;
`
export const Text = styled.a`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 14px;    
    color: #000;
    margin-bottom: 5px;
`

export const InfoTitle = styled.a`
    font-family: 'Nunito';
    font-size: 12px;    
    color: #6D7989;
    margin-bottom: 5px;
`

export const HalfContent = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px 10px;
    max-height: 100%;
`

export const Photo = styled.img`
    width: 180px;
    height: 180px;
    border-radius: 90px;
`

export const Card = styled.div`
    width: 100%;
    border-radius: 20px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    grid-gap: 15px 0px;
    transition: .5s;
    background: white;
    margin: 10px 0px 10px 0px;
`


export const FullWidthDiv = styled.div`
    width: calc(100% - 320px);
    height: 100%;
    background: ${defines.BackgroundColor};
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

export const Icon = styled.img`
    width: 120px;
    cursor: pointer;
`;

export const RightAlignedText = styled.a`
    font-family: 'Nunito';
    font-size: 22px;    
    color: ${defines.GreenTec};
`

export const AppTitleLeft = styled.a`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 22px;    
`

export const CenteredDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;

export const AppTitleLeftWrapper = styled.div`
    display: flex;
`;