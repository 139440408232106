import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import * as Functions from '../../../utils/functions/Functions'
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/store";
import AddIcon from '../../../assets/icons/add-icon.svg'
import SearchIcon from '../../../assets/icons/Search.svg'
import UpdatePlan from "./modals/updatePlan";
import DeletePlan from "./modals/deletePlan";
import CreatePlan from "./modals/createPlan";
import Table from "../../components/Table/Table";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

interface BasePlanoProps {
    idPlanoInSight?: string;
    idPlanosDHM?: string;
    idPlanoRelatorios?: string;
    nomePlano: string;
    preco: number;
    createdAt: string;
}

type PlanoProps = BasePlanoProps & {
    [key: string]: any;
    [key: number]: any;
};

const ManagePlans: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const acessLevel = useSelector((state: State) => state.acessLevel.acessLevel)
    const userType = useSelector((state: State) => state.userType.userType)

    const location = useLocation();
    const { app }: { app: string } = location.state; 

    const inputCompanyRef = useRef<any>()
    const [hasLenght, toggleHasLength] = useState(false)
    const [filteredPlans, setFilteredPlans] = useState<PlanoProps[]>([])

    const [planos, setPlanos] = useState<PlanoProps[]>([])
    const [editingPlanId, setEditingPlanId] = useState<string>('')
    const [editPlan, toggleEditPlan] = useState(false)
    const [deletePlan, toggleDeletePlan] = useState(false)
    const [createPlan, toggleCreatePlan] = useState(false)

    const getPlanos = () => {
        axios.get(defines.apiURL + "/api/PlanosCRUD?userTypeRequest=" + userType + "&api=" + app, { withCredentials: true })
            .then((response) => {
                const sortedPlanos = response.data.content.sort((a: PlanoProps, b: PlanoProps) => 
                    a.nomePlano.localeCompare(b.nomePlano)
                );
                setFilteredPlans(sortedPlanos);
                setPlanos(sortedPlanos);
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content);
            });
    }

    useEffect(()=>{
        getPlanos()
    },[dispatch, navigate])

    useEffect(()=>{
        setFilteredPlans(planos)
    }, [planos])

    const filterPlanData = (name: string) => {
        if(name.length === 0){
            setFilteredPlans(planos)
            toggleHasLength(false)
            return
        }
        toggleHasLength(true)
        const filtered = planos.filter(item => item.nomePlano.toUpperCase().includes(name.toUpperCase()))
        setFilteredPlans(filtered!)
    }

    const openEditPlan = (id: string) => {
        toggleEditPlan(true)
        setEditingPlanId(id)
    }

    const openDeletePlan = (id: string) => {
        toggleDeletePlan(true)
        setEditingPlanId(id)
    }

    const openCreatePlan = () => {
        toggleCreatePlan(true)
    }

    const getAplicativo = (app: string) => {
        switch (app) {
            case 'DHM':
                return 'DHM4.0'
            case 'REL':
                return 'Relatórios'
            case 'INS':
                return 'IndustrySight'
            default:
                navigate('/permissao')
                return ''
        }
    }

    return (
        <Body.Container>
            <Sidebar type={userType} content={defines.ContentSidebar.APPS_AND_PLANS} />
            <Body.FullWidthDiv>
                
                <Body.Row>
                    <Body.Row style={{ maxWidth: '100%', minWidth: '50px', justifyContent: 'space-between' }}>
                        <Breadcrumb aplicativo={getAplicativo(app)} />
                        <div style={{ display: 'flex', alignItems: 'right' }}>
                            <Body.FindContent style={{marginRight: '10px'}}>
                                    <Body.Find style={hasLenght?{width: '300px'}:{}}
                                        onChange={e => filterPlanData(e.target.value)}
                                        ref={inputCompanyRef} />
                                    <Body.SearchIcon src={SearchIcon} style={{cursor: 'text'}}
                                        onClick={()=>inputCompanyRef.current.focus()} />
                            </Body.FindContent>
                            { acessLevel === defines.CargoTectrol['Comercial'] || acessLevel === defines.CargoTectrol['Suporte']?
                                <Body.HeaderButton onClick={()=>openCreatePlan()} >
                                    <Body.SearchIcon src={AddIcon} style={{position: 'relative', top: 0, left: 0}} />
                                </Body.HeaderButton> : null
                            }
                        </div>
                    </Body.Row>                        
                </Body.Row>

                <Table
                    content={filteredPlans}
                    editPlan={openEditPlan}
                    deletePlan={openDeletePlan}
                    contentMap={app === 'INS' ? {
                        idPlanoInSight: 'ID',
                        nomePlano: 'Nome',
                        quotaUsuarios: 'Usuários permitidos',
                        quotaAtuadorSensor: 'Sensores e atuadores',
                        quotaEnvio: 'Requisições mensais',
                        preco: 'Quotas'
                    } : {
                        idPlanosDHM: 'ID',
                        nomePlano: 'Nome',
                        quotaUsuarios: 'Usuários permitidos',
                        preco: 'Preço'
                    }}
                    dynamicDescriptions={app === "INS" ? ["quotaUsuarios", "quotaAtuadorSensor", "quotaEnvio"] : ["quotaUsuarios"]}
                    viewOnly={acessLevel !== defines.CargoTectrol['Comercial'] && acessLevel !== defines.CargoTectrol['Suporte']}
                />

            </Body.FullWidthDiv>

            {createPlan?<CreatePlan toggle={toggleCreatePlan} idPlano={editingPlanId} planos={planos} regenerate={getPlanos} app={app}/>:null}
            {editPlan?<UpdatePlan toggle={toggleEditPlan} idPlano={editingPlanId} planos={planos} regenerate={getPlanos} app={app}/>:null}
            {deletePlan?<DeletePlan toggle={toggleDeletePlan} idPlano={editingPlanId} planos={planos} regenerate={getPlanos} app={app}/>:null}

        </Body.Container>
    );
}

export default ManagePlans