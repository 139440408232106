import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import * as Body from './Styles'
import SearchIcon from '../../../assets/icons/Search.svg'
import AddIcon from '../../../assets/icons/add-icon.svg'
import * as Functions from  '../../../utils/functions/Functions'
import * as defines from  '../../../utils/defines/Defines'
import * as Types from '../../../utils/types/Types'
import Input from "../../components/Inputs/Input/Input";
import Button from "../../components/Buttons/Button/Button";
import * as Modals from './Modals/Modals'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import IsAuth from "../../components/Modals/IsAuth/IsAuth";
import { State } from "../../redux/store";
import Avatar from '../../../assets/images/avatar.png'
import SendInvite from "./Modals/SendInvite";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import EditUser from "./Modals/EditUser";

type FilialProps = {
    idFilial: string,
    nomeFilial: string,
    nomeEmpresa: string,
    endereco: string,
    cnpj: string,
    cnpjFilial: string,
    tipoUsuarioEmpresarial: number | null
}
type UsuarioProps = {
    idUsuario: string,
    nomeCompleto: string,
    fotoUsuario: string | null,
    ddd: string,
    celular: string,
    email: string,
    tipoTectrol: number
}

const ManageTectrolUsers = () => {
    const userType = useSelector((state: State) => state.userType.userType)
    const acessLevel = useSelector((state: State) => state.acessLevel.acessLevel);
    
    const [hasLength, toggleHasLength] = useState(false)
    const [hasUser, toggleHasUser] = useState(false)
    const [modalCreateUser, toggleCreateUser] = useState(false)
    const [modalUnits, toggleUnits] = useState(false)
    const [modalDelete, toggleDelete] = useState(false)
    const [data, setData] = useState<UsuarioProps[]>([])
    const [filteredUsers, setFiltered] = useState<UsuarioProps[]>([])
    const [loading, toggleLoading] = useState(true)
    const [isTectrol, toggleTectrol] = useState(false)
    const [editUser, toggleEditUser] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [nomeCompleto, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setTelefone] = useState('')
    const [imagemUsuario, setImagem] = useState('')
    const [idUsuario, setId] = useState('')
    const [tipoTectrol, setTipoTectrol] = useState(0)
    const [loadedUserData, setUserData] = useState<UsuarioProps>()
    const [unidades, setUnidades] = useState<Types.Unidade[]>([])
    const inputRef = useRef<any>()

    const getUsers = () => {
        toggleLoading(true)
        axios.get(defines.apiURL+"/api/TectrolCRUD?userTypeRequest=" + userType,  {withCredentials: true})
            .then((response) => {
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
        }

    const selectUser = (id: string) => {
        const replacedId = Functions.ReplaceSpecialCharacters(id)
        if(id === idUsuario && hasUser){
            toggleHasUser(false)
            return
        }
        toggleLoading(true)
        setNome('')
        setDDD('')
        setEmail    ('')
        setTelefone('')
        setUserData(undefined)
        setId(id)
        axios.get(defines.apiURL+"/api/TectrolCRUD?id=" + Functions.ReplaceSpecialCharacters(replacedId) + "&userTypeRequest=" + userType,  {withCredentials: true})
            .then((response)=>{
                let responseData = response.data.content
                setNome(responseData.nomeCompleto)
                setDDD(responseData.ddd)
                setEmail(responseData.email)
                setImagem(responseData.fotoUsuario)
                setTelefone(responseData.celular)
                setUserData(responseData)
                setTipoTectrol(responseData.tipoTectrol)
                setId(id)
                
                toggleHasUser(true)
                toggleLoading(false)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
                return
            })
    }

    const filterData = (name: string) => {
        if(name.length === 0){
            setFiltered(data)
            toggleHasLength(false)
            return
        }
        toggleHasLength(true)
        const filtered = data.filter(item => item.nomeCompleto.toUpperCase().includes(name.toUpperCase()))
        setFiltered(filtered!)
    }

    const deleteUser = () => {
        toggleLoading(true)
        axios.delete(defines.apiURL+"/api/TectrolCRUD?id=" + Functions.ReplaceSpecialCharacters(idUsuario) + "&userTypeRequest=" + userType,  {withCredentials: true})
        .then((response)=>{
            dispatch({type: 'ADD_NOTIFICATION', status: 1, text: 'Usuário excluido com sucesso', title: ''})
            toggleHasUser(false)
            toggleDelete(false)
            getUsers()
            toggleLoading(false)
        })
        .catch((error)=>{
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }


    const updateUser = () => {
        if (!loadedUserData) return;

        const updatedFields: Partial<UsuarioProps> = {};

        if (email !== loadedUserData.email) updatedFields.email = email;
        if (celular !== loadedUserData.celular) updatedFields.celular = celular;
        if (ddd !== loadedUserData.ddd) updatedFields.ddd = ddd;
        if (nomeCompleto !== loadedUserData.nomeCompleto) updatedFields.nomeCompleto = nomeCompleto;

        if (Object.keys(updatedFields).length === 0) return;

        axios.put(defines.apiURL+"/api/UpdateUser?userTypeRequest=" + userType, 
            {...updatedFields, idUsuario: idUsuario, tipoTectrol: tipoTectrol},  {withCredentials: true})
            .then((response)=>{
                dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '',
                    text: 'Dados do usuario alterados com sucesso'})
                selectUser(idUsuario)
                getUsers()
            })
            .catch((error)=>{
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            })
    }


    const getCargo = (id: number) => {
        switch(id){
            case defines.CargoTectrol['Comercial']:
                return "Comercial";
            case defines.CargoTectrol['Engenheiro']:
                return "Engenharia";
            case defines.CargoTectrol['Suporte']:
                return "Suporte";
            case defines.CargoTectrol['Padrão']:
                return "Padrão";
            default:
                return "Cargo Indisponível";
        }
    }

    const regenerate = () => {
        toggleLoading(true)
        setNome('');
        setEmail('');
        setDDD('');
        setTelefone('');
        setImagem('');
        setId('');
        setTipoTectrol(0);
        setUserData(undefined);
        setUnidades([]);
        toggleHasLength(false);
        toggleHasUser(false);
        toggleCreateUser(false);
        toggleUnits(false);
        toggleDelete(false);
        toggleLoading(false);
        toggleTectrol(false);
        toggleEditUser(false);
        axios.get(defines.apiURL+"/api/TectrolCRUD?userTypeRequest=" + userType, {withCredentials: true})
            .then((response) => {
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }

    useEffect(()=>{

        toggleLoading(true)
        axios.get(defines.apiURL+"/api/TectrolCRUD?userTypeRequest=" + userType, {withCredentials: true})
            .then((response) => {
                const sorted = Functions.SortResponse(response.data.content, "nomeCompleto")
                setData(sorted)
                setFiltered(sorted)
                toggleLoading(false)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
                toggleLoading(false)
            })
    }, [dispatch, navigate])


    return <Body.Container>
        <Sidebar type={userType} content={defines.ContentSidebar.MANAGE_USERS} />
        <Body.Content style={{flexDirection: 'row'}}>
            <Body.HalfContent style={hasUser?{width: 'calc(100% - 120px)'}:{width: '100%'}}>
                <Body.Row style={{width: "100%", height: '140px',
                    justifyContent: 'space-between', alignItems: 'center'}}>
                    <Breadcrumb />
                    <Body.Row style={{maxWidth: '90%', minWidth: '50px'}}>
                        <Body.FindContent>
                            <Body.Find style={hasLength?{width: '300px'}:{}}
                                onChange={e => filterData(e.target.value)}
                                ref={inputRef} />
                            <Body.Icon src={SearchIcon} style={{cursor: 'text'}}
                                onClick={()=>inputRef.current.focus()} />
                        </Body.FindContent>
                    {acessLevel === defines.CargoTectrol["Suporte"] && (
                        <Body.HeaderButton onClick={() => toggleCreateUser(true)}>
                            <Body.Icon src={AddIcon} style={{ position: 'relative', top: 0, left: 0 }} />
                        </Body.HeaderButton>
                    )}
                    </Body.Row>                        
                </Body.Row>
                <Body.Scroll style={{ maxHeight: 'calc(100% - 120px)' }}>
                    {filteredUsers.length === 0 ? (
                        <Body.Text>Nenhum usuário encontrado</Body.Text>
                    ) : (
                        filteredUsers.map((item, index) => (
                            <Body.SelectUser onClick={() => selectUser(item.idUsuario)} key={index.toString()}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <Body.Photo src={item.fotoUsuario || Avatar} />
                                    <Body.Text>{item.nomeCompleto}</Body.Text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Body.NoteText>{getCargo(item.tipoTectrol)}</Body.NoteText>
                                </div>
                            </Body.SelectUser>
                        ))
                    )}
                </Body.Scroll>

            </Body.HalfContent>
            {hasUser?<Body.HalfContent style={{alignItems: 'center'
                    , width: hasUser?'100px':'0px'}}>
                    <Body.Photo src={imagemUsuario ? imagemUsuario : Avatar} style={{width: '120px',
                        borderRadius: '60px',height: '120px'}}/>
                    <Body.Scroll style={{height: '100%', marginBottom: '10px',
                        maxHeight: 'calc(100vh - 80px)',
                        display: 'flex', flexDirection: 'column', gridGap: '20px 0px'}}>
                        <Input text={nomeCompleto} setText={setNome} title="Nome Completo" disabled={acessLevel !== defines.CargoTectrol["Suporte"]} />
                        <Input text={email} setText={setEmail} title="Email" disabled={acessLevel !== defines.CargoTectrol["Suporte"]} />
                        <Body.Row>
                            <div style={{width: '20%'}}>
                                <Input text={ddd} setText={setDDD} title="DDD"
                                    limit={2} disabled={acessLevel !== defines.CargoTectrol["Suporte"]} />
                            </div>
                            <div style={{width: '79%'}}>
                                <Input text={celular} setText={setTelefone} title="Telefone" disabled={acessLevel !== defines.CargoTectrol["Suporte"]} />
                            </div>
                        </Body.Row>
                    </Body.Scroll>
                    {acessLevel === defines.CargoTectrol["Suporte"] && (
                        <>
                        <Body.Row>
                            <Button text="Alterar Tipo" color="yellow" action={()=>toggleEditUser(true)} width={320}/>
                        </Body.Row>
                        <Body.Row>
                            <Button text="Salvar" action={()=>updateUser()} width={155}/>
                            <Button text="Apagar usuário" action={()=>toggleDelete(true)} width={155}
                                color="red"/>
                        </Body.Row>
                        </>
                    )}
            </Body.HalfContent>:null}
        </Body.Content>

        {modalDelete?<Modals.ModalDeleteUser toggle={toggleDelete} idUsuario={idUsuario}
            nome={nomeCompleto} action={deleteUser} />:null}
        {loading?<Loading />:null}
        {editUser?<EditUser toggle={toggleEditUser} usuario={loadedUserData!} regenerate={regenerate} />:null}
        {modalCreateUser?<SendInvite toggle={toggleCreateUser} />: null}

    </Body.Container>
}

export default ManageTectrolUsers
