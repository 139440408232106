import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import * as Body from './Styles'
import * as defines from '../../../utils/defines/Defines'
import Input from '../../components/Inputs/Input/Input'
import Button from '../../components/Buttons/Button/Button'
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG/Button'
import CodeInput from '../../components/Inputs/InputCode/Input'
import TectrolLogo from '../../../assets/icons/tectrol-logo.svg';
import DinamoLogo from '../../../assets/icons/dinamo-logo.svg';
import * as Functions from '../../../utils/functions/Functions'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import LogoConecta from '../../../assets/images/logoConecta.png'
import BrilhoIcon from '../../../assets/icons/brilho-icone.png'
import ProgramadoraIcon from '../../../assets/icons/programadora-icone.png'
import SurpresoIcon from '../../../assets/icons/surpreso-icone.png'
import ConstrucaoIcon from '../../../assets/icons/construcao-icone.png'
import PlacaConstrucaoIcon from '../../../assets/icons/placa-construcao-icone.png'
import DesenvolvimentoIcon from '../../../assets/icons/desenvolvimento-icone.png'

const NotAvailable = () => {
    const navigate = useNavigate()
 
    return (
        <Body.Container>
            <Body.Row style={{ justifyContent: 'center', marginTop: '30px' }}>
                <Body.CompanyLogo style={{height: '80px'}} src={LogoConecta} />
            </Body.Row>
            <Body.Card>
            <Body.Row>
                <Body.FloatIcon1 style={{ height: '80px', marginRight: '-30px' }} src={PlacaConstrucaoIcon} />
                <Body.FloatIcon2 style={{ height: '80px', marginRight: '-30px' }} src={DesenvolvimentoIcon} />
                <Body.FloatIcon3 style={{ height: '60px', marginLeft: '-20px', marginBottom: '20px' }} src={BrilhoIcon} />
            </Body.Row>
                <Body.Row style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <Body.Title>Estamos reformando a versão para celular do Conecta!</Body.Title>
                </Body.Row>
                <Body.Row style={{marginTop: '20px', justifyContent: 'center', textAlign: 'center'}}>
                    <Body.SubTitle>Enquanto isso, utilize seu computador para acessar a plataforma.</Body.SubTitle>
                </Body.Row>
            </Body.Card>
    
        </Body.Container>
    )
}

export default NotAvailable