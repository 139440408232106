import * as Actions from "./actionsType";

const selectedUnity = (
  state: Actions.SelectUnityProps = { selectedUnity: '' },
  action: { type: string; selectedUnity: string } 
) => {
  switch (action.type) {
    case Actions.SET_SELECTED_UNITY:
      return { selectedUnity: action.selectedUnity };
    default:
      return state;
  }
};

export default selectedUnity;
