import styled, { keyframes } from "styled-components";
import * as defines from '../../../../utils/defines/Defines';

export const Button = styled.a<{ size?: number; active?: boolean; color?: string , hoverColor?: string}>`
  cursor: pointer;
  color: ${props => props.color ? props.color : (props.active ? defines.BlueTec : "#000")};
  font-family: "Comfortaa";
  font-size: ${props => props.size ? props.size + "px" : "12px"};
  :hover {
    color: ${props => props.hoverColor ? props.hoverColor : (props.color ? props.color : defines.BlueTec)};
  }
`;

const rotate = keyframes`
  from {
  transform: rotate(0deg);
  }

  to {
  transform: rotate(360deg);
  }
`;

export const Icon = styled.img`
  height: 25px;
  width: 25px;
  animation: ${rotate} .8s linear infinite;
`;