import styled, {keyframes} from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const Container = styled.div`
    background: ${defines.BackgroundColor};
    width: 100vw;
    height: 100vh;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: flex-end;
`

export const Content = styled.div`
    width: calc(100vw - 420px);
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    display: flex;
    grid-gap: 30px 30px;
    justify-content: center;
    align-items: left;    
    flex-direction: column;
`

export const Title = styled.h1`
    font-family: 'Nunito';
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
`

export const Row = styled.div`
    display: flex;
    grid-gap: 0px 20px;
`

export const TitleRow = styled.div`
    display: flex;
    justify-content: flex-start;
    grid-gap: 0px 5px;
`

export const ResultContent = styled.div`
    display: flex;
    flex-direction: column;
`

export const SubTitle = styled.a`
    font-family: 'Nunito';
    font-size: 14px;    
    color: #6D7989;
    margin-bottom: 5px;
    height: 80px;
`
export const Root = styled.a`
    text-align: left;
    font-family: 'Nunito';
    font-size: 18px;    
    color: #6D7989;
`

export const Page = styled.a`
    text-align: left;
    font-family: 'Nunito';
    font-size: 18px;    
    color: #000;
`

export const AppCompany = styled.a`
    text-align: left;
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 18px;    
    color: #000;
`

export const HalfContent = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px 10px;
    max-height: 100%;
`

export const Photo = styled.img`
    width: 180px;
    height: 180px;
    border-radius: 90px;
`

export const Card = styled.div`
    width: 270px;
    height: 100%;
    min-height: 400px;
    max-height: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    grid-gap: 10px 0px;
    text-align: center;
    justify-content: space-between;
`

export const Icon = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 12px;
`

export const UserIcons = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 12px;
`


export const Filial = styled.a`
    font-family: 'Nunito';
    font-size: 14px;
    color: ${defines.BlueTec};
`

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
`

export const IconWrapper = styled.div<{ hasSubscription: boolean }>`
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ hasSubscription }) => hasSubscription ? defines.GreenTec : '#F34A4A'};
    border-radius: 50%;
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
`;

export const PlanDescription = styled.a`
    font-family: 'Nunito';
    font-size: 14px;    
    color: #000;
    margin-bottom: 5px;
    margin-top: -20px;
    height: 80px;

`

export const Expiration = styled.a`
    margin-bottom: -10px;
    font-family: 'Nunito';
    font-size: 12px;
    color: #6D7989;
`

export const ExpirationDate = styled.a`
    font-family: 'Nunito';
    font-size: 16px;
    color: #000;
`

export const SmallIconWrapper = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${defines.BlueTec};
    border-radius: 50%;
    margin-left: 90px;
`

export const SmallIcon = styled.img`
    width: 14px;
    height: 14px;
    border-radius: 12px;
`

export const ActivePlanTitle = styled.a`
    display: flex;
    justify-content: flex-start;
    grid-gap: 0px 5px;
`

export const PlanTitle = styled.a`
    font-family: 'Nunito';
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
`

export const HorizontalCard = styled.div`
    width: 530px;
    height: 60px;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
`

export const UserName = styled.a`
    font-family: 'Nunito';
    font-size: 16px;
    color: #000;
`

export const UserRole = styled.a`
    font-family: 'Nunito';
    font-size: 16px;
    color: #6D7989;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 10px 10px;
`

export const UsuariosVinculados = styled.div`
    display: flex;
    justify-content: flex-start;
    justify-content: space-between;
    grid-gap: 0px 5px;
`
export const UsuariosVinculadosTitle = styled.a`
    font-family: 'Nunito';
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
`

export const UsuariosVinculadosIcons = styled.div`
    display: flex;
    justify-content: flex-start;
`
export const UsuariosVinculadosAddIcon = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${defines.BlueTec};
    border-radius: 50%;
    margin-left: 10px;
`

export const UsuariosVinculadosSearchIcon = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${defines.BlueTec};
    border-radius: 50%;
    margin-left: 10px;
`

export const Find = styled.input`
    width: 30px;
    height: 30px;
    max-width: 200px;
    border-radius: 15px;
    border: 1px solid #1080D6;
    padding-left: 30px;
    font-family: "Nunito";
    transition: .5s;
    background: none;
    z-index: 5;
    :focus{
        transition: .5s;
        width: 300px;
    }
`

export const FindContent = styled.div`
    min-width: 30px;
    position: relative;
`

export const ModalIcon = styled.img`
    top: 7.5px;
    left: 7.5px;
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 3;
`

export const Scroll = styled.div`
    width: 100%;
    padding-top: 10px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none; 
    }
    box-sizing: border-box; 
    max-height: 50vh;
`

export const SelectUser = styled.div`
    grid-gap: 0px 10px;
    width: 100%;
    height: 60px;
    border-radius: 12px;
    border: 1px solid rgba(200,200,200,0.5);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    transition: .5s;
    :hover {
        transition: .5s;
        background: rgba(0,0,0,0.02);
    }
`

export const SubscriptionCard = styled.div`
    grid-gap: 0px 10px;
    width: 100%;
    height: 160px;
    border-radius: 12px;
    border: 1px solid rgba(200,200,200,0.5);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    transition: .5s;
    :hover {
        transition: .5s;
        background: rgba(0,0,0,0.02);
    }
`

export const Text = styled.a`
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 14px;    
    color: #000;
    margin-bottom: 5px;
`

export const RedIcon = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F34A4A;
    border-radius: 50%;
    img {
        width: 20px;
        height: 20px;
    }
`;

export const GreenIcon = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #92B258;
    border-radius: 50%;
    img {
        width: 20px;
        height: 20px;
    }
`;
export const CompanyStatus = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    font-family: 'Nunito';
    font-size: 14px;    
`;

export const Empresa = styled.a`
    font-family: 'Nunito';
    font-size: 14px;    
    color: #6D7989;
`

export const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin: 20px 0;
`;

export const HeaderButton = styled.div`
    background-color: #1080D6;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
        transition: .3s;
        opacity: 0.8
    }
`

export const HeaderIcon = styled.img`
    top: 7.5px;
    left: 7.5px;
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 3;
`

export const Cargo = styled.a`
    font-family: 'Nunito';
    font-size: 14px;    
    color: grey;
    margin-bottom: 5px;
`

export const NoteText = styled.a`
    font-family: 'Nunito';
    font-size: 12px;    
    color: grey;
    margin-bottom: 5px;
`

export const TextError = styled.a`
    font-family: "Comfortaa";
    font-size: 10px;
    color: ${defines.Red};
`

export const PlanName = styled.a`
    font-family: 'Nunito';
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
`

export const ModalCard = styled.div`
    width: 100%;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    grid-gap: 15px 0px;
    transition: .5s;
    background: white;

    &:hover {
        background: #ebebeb;
    }
`

export const ModalCardIcon = styled.img`
    margin-right: 15px;
    width: 47px;
    z-index: 3;
`

export const ButtonTitle = styled.a`
    font-family: "Comfortaa";
    font-size: 16px;
    font-weight: bold;
    color: #000;
    max-width: 300px;
    word-wrap: break-word;
`

export const SelectCard = styled.div<{active?: boolean}>`
    width: 150px;
    border-radius: 12px;
    color: #FFF;
    padding: 20px;
    border: 1px solid ${props => props.active?defines.BlueTec:"rgba(0,0,0,0.05)"};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px 0px;
`

export const IconChangePermission = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: ${defines.BackgroundColor};
`   


export const ModalCardHistory = styled.div`
    width: 100%;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    box-sizing: border-box;
    grid-gap: 15px 0px;
    transition: .5s;
    background: white;

    &:hover {
        background: #ebebeb;
    }
`


const TextAnimate = keyframes`
    from{
        margin-top: -10px;
        opacity: 0;
    }
    to{
        margin-top: 0px;
        opacity: 1;
    }
`

export const TextWarning = styled.a`
    font-family: "Comfortaa";
    font-size: 10px;
    color: ${defines.Yellow};
    animation: .35s ${TextAnimate} ease-in;
`