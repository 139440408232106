import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "../../../components/Modals/Modal/Modal";
import Input from "../../../components/Inputs/Input/Input";
import * as Body from '../Styles'
import * as Functions from '../../../../utils/functions/Functions'
import * as Defines from '../../../../utils/defines/Defines'
import Button from "../../../components/Buttons/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { State } from "../../../redux/store";

interface BasePlanoProps {
    idPlanoInSight?: string;
    idPlanoDHM?: string;
    idPlanoRelatorios?: string;
    nomePlano: string;
    preco: number;
    createdAt: string;
}

type PlanoProps = BasePlanoProps & {
    [key: string]: any;
    [key: number]: any;
};

type DeletePlanProps = {
    toggle: Dispatch<SetStateAction<boolean>>,
    idPlano: string,
    planos: PlanoProps[],
    regenerate: () => void
    app: string
};


export const DeletePlan: React.FC <DeletePlanProps> = ({
    toggle, idPlano, planos, regenerate, app
}) => {
    const [loading, toggleLoading] = useState(false)
    const userType = useSelector((state: State) => state.userType.userType)


    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
    }, [dispatch, navigate])

    const DeletePlanProps = () => {
        toggleLoading(true)
        axios.delete(Defines.apiURL+"/api/PlanosCRUD?userTypeRequest=" + userType + "&id=" + Functions.ReplaceSpecialCharacters(idPlano) + "&api=" + app, {withCredentials: true})
        .then(response => {
            dispatch({type: 'ADD_NOTIFICATION', status: 1, title: '', text: 'Plano deletado com sucesso'})
            regenerate()
            toggle(false) 
            toggleLoading(false)
        })
        .catch(error => {
            dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: error.response.data.message})
            Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content)
            toggleLoading(false)
        })
    }

    return <Modal title="Deletar Plano" subtitle="Tem certeza que deseja deletar o plano?" toggleModal={toggle}>
        <div style={{width: '500px', display: 'flex', flexDirection: 'column',
               gridGap: '15px 0px' }}>
            <br />
            <Body.Row>
                <Button text="Cancelar" action={() => {toggle(false)}}/>
                <Button color="red" text="Deletar" action={() => {DeletePlanProps()}} loading={loading} />
            </Body.Row>
            <br />
        </div >
    </Modal>
}

export default DeletePlan