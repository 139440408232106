import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Input from "../../../components/Inputs/Input/Input";
import Modal from "../../../components/Modals/Modal/Modal";
import * as Types from '../../../../utils/types/Types'
import * as Body from '../Styles'
import * as defines from '../../../../utils/defines/Defines'
import * as Functions from '../../../../utils/functions/Functions'
import Button from "../../../components/Buttons/Button/Button";
import { State } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AcessLevelProps } from "../../../redux/acessLevel/actionsType";
import axios from "axios";
import { verifyEmail } from "../../../../utils/functions/Functions";
import { useNavigate } from "react-router-dom";
import SearchIcon from '../../../../assets/icons/Search.svg'
import CheckIcon from '../../../../assets/icons/checkIconMaterial.svg'
import CloseIcon from '../../../../assets/icons/closeIconMaterial.svg'
import DatetimeInput from "../../../components/DatetimeInput/DatetimeInput";
import ArrowDownIcon from '../../../../assets/icons/arrow-down-blue-material-icon.svg'
import ButtonWithoutBG from "../../../components/Buttons/ButtonWithoutBG/Button";
import { text } from "stream/consumers";

type FilialProps = {
    idFilial: string;
    nomeFilial: string;
    cnpjFilial: string;
    statusAssinatura: number;
    idEmpresa: string;
    cnpjEmpresa: string;
    nomeEmpresa: string;
}

type PeriodoProps = {
    idPeriodo: string;
    dataInicial: string;
    dataVencimento: string;
    nomePlano: string;
    ativo: number;
    periodos: PlanoProps[];
}

interface BasePlanoProps {
    idPlanoInSight?: string;
    idPlanoDHM?: string;
    idPlanoRelatorios?: string;
    nomePlano: string;
    preco: number;
    createdAt: string;
}

type PlanoProps = BasePlanoProps & {
    [key: string]: any;
    [key: number]: any;
};

type SelectUnitProps = {
    toggle: Dispatch<SetStateAction<boolean>>
    filial: FilialProps
    app: string
}

const SeeHistory: React.FC<SelectUnitProps> = ({
    toggle, filial, app
}) => {

    const [loading, toggleLoading] = useState(false)
    const [assinaturas, setAssinaturas] = useState<PeriodoProps[]>([])
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null); // Estado para rastrear o índice expandido
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userType = useSelector((state: State) => state.userType.userType);

    useEffect(() => {
        toggleLoading(true)
        axios.get(`${defines.apiURL}/api/AssinaturaCRUD?subscriptionHistory=1&api=${app}&idFilial=${Functions.ReplaceSpecialCharacters(filial.idFilial)}&userTypeRequest=${userType}`, { withCredentials: true })
            .then((response) => {
                setAssinaturas(response.data.content.periods)
            })
            .catch((error) => {
                Functions.ResolveErrors(error.response.status, error.response.data.code, error.response.data.message, navigate, dispatch, error.response.data.content[0]);
            })
            .finally(() => {
                toggleLoading(false)
            });

    }, [dispatch, navigate])

    // Função para alternar a expansão de um período específico
    const toggleExpansion = (index: number) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };


    const formatDate = (isoDate: string): string => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <Modal title="Históricos de Assinaturas" toggleModal={toggle} loading={loading}>
            <div style={{ width: '700px', display: 'flex', flexDirection: 'column', gridGap: '15px 0px', alignItems: 'center', justifyContent: 'center', transition: 'all 0.3s ease' }}>
                <Body.Scroll>
                {!assinaturas ? (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <ButtonWithoutBG action={() => {toggle(false)}} text="Nenhum histórico foi encontrado." />
                    </div>
                ) : 
                assinaturas.map((item, index) => (
                    <div
                        key={item.idPeriodo}
                        style={{ backgroundColor: 'white', borderRadius: '15px', padding: '10px', width: '100%', marginTop: '10px', cursor: 'pointer', transition: 'all 0.3s ease' }}
                        onClick={() => toggleExpansion(index)}
                    >
                        <Body.Row style={{ alignItems: 'center' }}>
                            <Body.Row>
                                <Input text={item.periodos[0].nomePlano || "Plano"} setText={() => { }} disabled title="Nome do Plano" />
                            </Body.Row>
                            <Body.Row>
                                <DatetimeInput text={item.dataInicial} setText={() => { }} title="Data Inicial" disabled removeHours />
                                <DatetimeInput text={item.dataVencimento} setText={() => { }} title="Data de Vencimento" disabled removeHours />
                            </Body.Row>
                            <Body.Row>
                                <Body.Icon
                                    src={ArrowDownIcon}
                                    style={{
                                        transform: expandedIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.4s ease'
                                    }}
                                />
                            </Body.Row>
                        </Body.Row>
                        
                        {/* Condicional para expandir e mostrar os planos */}
                        {expandedIndex === index && (
                            <div style={{ marginTop: '10px' }}>
                                {item.periodos.map((plano) => (
                                    <Body.Row key={plano.idPlano}>
                                        <div style={{ border: '1px solid', borderColor: 'lightGrey', borderRadius: '15px', padding: '10px', width: '100%', marginTop: '10px', transition: 'all 0.3s ease' }}>
                                            <Body.Row>
                                                <div style={{borderLeft: '5px solid ' + defines.BlueTec, borderRadius: '0 15px 15px 0', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', fontFamily: 'Nunito, sans-serif', transition: 'all 0.3s ease'}}>
                                                    <span style={{ fontSize: '14px', color: defines.BlueTec }}>
                                                        {formatDate(plano.dataInicial)}
                                                    </span>
                                                    <span style={{ fontSize: '14px', color: defines.BlueTec }}>
                                                        {formatDate(plano.dataVencimento)}
                                                    </span>
                                                </div>
                                                <div>
                                                    {
                                                        app === 'INS' ? (
                                                            <>
                                                                <Body.Row>
                                                                    <Input text={plano.quotaUsuarios.toString()} setText={() => { }} disabled title="Quota de Usuários" />
                                                                    <Input text={plano.quotaAtuadorSensor.toString()} setText={() => { }} disabled title="Quota de Atuadores e Sensores" />
                                                                </Body.Row>
                                                                <Body.Row>
                                                                    <Input text={plano.quotaEnvio.toString()} setText={() => { }} disabled title="Quota de Envios" />
                                                                    <Input text={plano.preco.toString()} setText={() => { }} disabled title="Preço" />
                                                                </Body.Row>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Body.Row style={{height: '100px'}}>
                                                                    <Input text={plano.quotaUsuarios.toString()} setText={() => { }} disabled title="Quota de Usuários" />
                                                                    <Input text={plano.preco.toString()} setText={() => { }} disabled title="Preço" />
                                                                </Body.Row>
                                                            </>
                                                        )
                                                    }

                                                </div>
                                            </Body.Row>
                                        </div>
                                    </Body.Row>
                                ))}
                            </div>
                        )}
                    </div>
                ))
                }
                </Body.Scroll>
            </div>
        </Modal>
    );
}

export default SeeHistory;
