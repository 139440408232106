import styled from 'styled-components';

export const Input = styled.input<{ error: boolean; width?: number }>`
  position: relative;
  width: ${(props) => (props.width ? props.width + 'px' : '100%')};
  height: 45px;
  background: #fff;
  border-radius: 12px;
  font-family: 'Comfortaa';
  background-color: rgba(82, 146, 209, 0.1);
  border: solid 1px;
  border-color: rgba(82, 146, 209, 0.1);
  outline: none;
  transition: 0.35s;
  padding-left: 15px;
  box-sizing: border-box;
  :focus {
    border: solid 1px #000;
    padding-left: 20px;
    transition: 0.35s;
  }
`;

export const Row = styled.div`
  display: flex;
  grid-gap: 10px;
  align-items: center;
  justify-content: space-between;
`

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.05);
  }
  :active {
    transform: scale(0.95);
  }
`;

export const Content = styled.div<{ width?: number }>`
  flex-direction: column;
`;

export const Text = styled.a`
    font-family: 'Nunito';
    font-size: 14px;    
    color: #6D7989;
`
export const TitleContent = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
`;

export const EnableButton = styled.div<{ enabled?: boolean }>`
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s linear;
  background: ${(props) => (props.enabled ? '#92B258' : 'rgba(197, 40, 44, 1)')};
`;

export const EnableButtonIcon = styled.img`
  width: 10px;
  height: 10px;
`;
