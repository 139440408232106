import React, { useEffect, useRef, useState } from 'react';
import * as Body from './Styles';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DataPicker.css';
import { ptBR } from 'date-fns/locale/pt-BR';

import dateIcon from '../../../assets/icons/edit-calendar-material-icon.svg';
import dateDisabledIcon from '../../../assets/icons/calendario-cinza-material-icon.svg';

registerLocale('pt-BR', ptBR);

type InputProps = {
  text: string;
  placeholder?: string;
  setText: (value: string) => void;
  callbackSetTextWithoutTimezone?: (value: string) => void;
  width?: number;
  error?: boolean;
  title?: string;
  limit?: number;
  action?: any;
  onClick?: any;
  disabled?: boolean;
  value?: string;
  removeHours?: boolean;
};

const DatetimeInput: React.FC<InputProps> = ({
  text,
  setText,
  callbackSetTextWithoutTimezone,
  title,
  onClick,
  disabled,
  value,
  removeHours,
}) => {
  const [startDate, setStartDate] = useState<Date>(new Date(text));
  const [enable, toggleEnable] = useState(true);
  const datePickerRef = useRef<DatePicker>(null);

  // Atualiza a data inicial quando `text` mudar, garantindo que seja um formato válido de data
  useEffect(() => {
    if (text) {
      try {
        const parsedDate = new Date(text);
        setStartDate(parsedDate);
      } catch {
        console.error("Data inválida");
      }
    }
  }, [text]);

  // Atualiza `text` (ISO string) e UTC quando `startDate` mudar
  useEffect(() => {
    if (enable && startDate) {
      setText(startDate.toISOString()); // Atualiza para ISO
      if (callbackSetTextWithoutTimezone) {
        const utcDate = convertDateToUTCISO(startDate);  // Converte para UTC
        callbackSetTextWithoutTimezone(utcDate); // Atualiza a versão UTC
      }
    } else {
      setText(''); // Se desabilitado, limpa o valor
    }
  }, [enable, startDate, setText, callbackSetTextWithoutTimezone]);

  const convertDateToUTCISO = (date: Date) => {
    const newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    return newDate.toISOString();
  };

  const handleChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
      setText(date.toISOString());
      callbackSetTextWithoutTimezone && callbackSetTextWithoutTimezone(convertDateToUTCISO(date));
    }
  };

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };

  return (
    <Body.Content>
      {title ? (
        <Body.TitleContent>
          <Body.Text>{title}</Body.Text>
        </Body.TitleContent>
      ) : null}
      <Body.Row>
        <DatePicker
          ref={datePickerRef}
          disabled={disabled}
          selected={startDate}
          onChange={handleChange}
          showTimeSelect={!removeHours}
          dateFormat={removeHours ? 'P' : 'Pp'}
          timeFormat="p"
          timeIntervals={15}
          timeCaption="Hora"
          locale="pt-BR"
        />
        <Body.Icon
          src={disabled ? dateDisabledIcon : dateIcon}
          onClick={handleIconClick}
        />
      </Body.Row>
    </Body.Content>
  );
};

export default DatetimeInput;
