import React from "react";
import * as Body from './Styles';
import Spinner from "../../../../assets/icons/spinner-blue.svg";

type ButtonWrapperProps = {
    action: () => void,
    size?: number,
    active?: boolean,
    loading?: boolean,
    style?: React.CSSProperties,
    children: React.ReactNode
}

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({
    action, size, active, loading, style, children
}) => {
    return loading ? (
        <Body.Icon src={Spinner} />
    ) : (
        <Body.Button onClick={action} active={active} style={style}>
            {children}
        </Body.Button>
    );
}

export default ButtonWrapper;