import styled from "styled-components";
import * as defines from '../../../utils/defines/Defines'

export const TableWrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const TableNoSpacing = styled.table`
    font-family: 'Nunito';
    border-spacing: 0px; /* Define o espaçamento geral */
    background-color: ${defines.BackgroundColor};
    font-size: 14px;
`;

export const Table = styled.table`
    font-family: 'Nunito';
    border-spacing: 10px; /* Define o espaçamento geral */
    background-color: ${defines.BackgroundColor};
    font-size: 14px;
`;

export const CombinedTdTitle1 = styled.td`
    width: 220px;
    height: 35px;
    min-width: 220px;
    max-width: 220px;
    font-family: 'Nunito';
    padding: 15px;
    text-align: left;
    position: relative;
    background-color: white;
    font-weight: bold;
    border-right: 0px; /* Remove qualquer borda direita para unir as duas colunas */
    border-radius: 10px 0px 0px 10px;
    color: ${defines.BlueTec};
`

export const CombinedTdTitle2 = styled.td`
    width: 120px;
    height: 35px;
    min-width: 120px;
    max-width: 120px;
    font-family: 'Nunito';
    padding: 15px;
    text-align: center;
    position: relative;
    background-color: white;
    font-weight: bold;
    border-left: 0px; /* Remove qualquer borda esquerda para unir as duas colunas */
    border-radius: 0px 10px 10px 0px;
    color: ${defines.BlueTec};
`

export const TdTitleBold = styled.td`
    width: 220px;
    height: 35px;
    min-width: 220px;
    max-width: 220px;
    font-family: 'Nunito';
    border-radius: 10px;
    padding: 15px;
    text-align: left;
    position: relative;
    background-color: white;
    font-weight: bold;
`

export const TdBold = styled.td`
    width: 110px;
    height: 35px;
    min-width: 110px;
    max-width: 110px;
    font-family: 'Nunito';
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    position: relative;
    background-color: white;
    font-weight: bold;
`

export const TdTitle = styled.td`
    width: 220px;
    height: 35px;
    min-width: 220px;
    max-width: 220px;
    font-family: 'Nunito';
    border-radius: 10px;
    padding: 15px;
    text-align: left;
    position: relative;
    background-color: white;
`

export const Td = styled.td`
    width: 110px;
    height: 35px;
    min-width: 110px;
    max-width: 110px;
    font-family: 'Nunito';
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    position: relative;
    background-color: white;
`